import React, { Component } from 'react';
import { Dropdown, Button, Form, Col, Table } from 'react-bootstrap';
import Plot from 'react-plotly.js';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import axios from 'axios';  


//This defines the color scales for the heatmaps shown
var colorscaleValue = [
    [0, '#3D9970'],
    [1, '#001f3f']
];

var config = require('./config.json');

//This function verifies the cookies and makes sure that the login session is 
//still valid.
function verifyCookies()
{
    if (Cookies.get('Auth') === undefined || Cookies.get('Username') === undefined)
    {
        console.error('Not logged in!');
        window.location.reload();
    }
    else
    {
        var in10Minutes = 1 / 144;
        Cookies.set('Auth', Cookies.get('Auth'), {expires : in10Minutes});
        Cookies.set('Username', Cookies.get('Username'), {expires : in10Minutes});
    }
}

class Patterns extends Component
{
    //This makes sure that the Patterns component is constructed with the new database
    //and its user to make web requests with
    static propTypes = {
        new_database : PropTypes.object.isRequired,
        user : PropTypes.string.isRequired,
        patternOperations: PropTypes.object.isRequired,
        onRefreshEvents: PropTypes.func.isRequired,
    };

    constructor(props)
    {
        super(props);
        
        console.log(`PATTERNS CONSTRUCTOR: This is the user ID I have to work with ${this.props.user}`);
        
        //This loads up the events for the new database that 
        //the component is constructed from 
        let events = this.getEventsFromDataSet();
        console.log(`This events ${events}`);

        //These are the pattern types currently supported. 
        let patternTypes = ['Concurrent', 'Conditional Sequential'];

        //This is a dummy initialization of the heatmap data and what it is 
        //expected to look like. 
        let heatmapData = [
            {
                title : "HR while Exercise",
                xValues : ['A', 'B', 'C', 'D', 'E'],
                yValues : ['W', 'X', 'Y', 'Z'],
                type : 'Concurrent',
                event1 : 'heartrate',
                event2 : 'exercise',
                zValues : [
                    [0.00, 0.00, 0.75, 0.75, 0.00],
                    [0.00, 0.00, 0.75, 0.75, 0.00],
                    [0.75, 0.75, 0.75, 0.75, 0.75],
                    [0.00, 0.00, 0.00, 0.75, 0.00]
                ]
            }
        ];


        this.state = {
            events : events,
            selectedEvent1 : events[0],
            selectedEvent2 : events[1],
            disableAddPatterns : true,
            patternTypes : patternTypes,
            selectedPatternType : patternTypes[0],
            timeRange : {
                beginHour : 0,
                endHour : 0,
                endGTbegin : false
            },
            patternHeatmapData : this.props.patternOperations,//heatmapData,
            selectedHeatmap : null,//heatmapData[0],
            patternName : ''
        };
    }

    getEventsFromDataSet()
    {
        let events = new Set();
        this.props.new_database.forEach((doc) => {
            events.add(doc.type);
        });
        return [...events];
    }

    handleDropDownSelect1 = (key, event) =>
    {
        verifyCookies();
        this.setState({selectedEvent1 : key, disableAddPatterns : key === this.state.selectedEvent2});
    }

    handleDropDownSelect2 = (key, event) =>
    {
        verifyCookies();
        this.setState({selectedEvent2 : key, disableAddPatterns : key === this.state.selectedEvent1});
    }

    handlePatternTypeSelect = (key, event) =>
    {
        verifyCookies();
        this.setState({selectedPatternType : key});
    }

    handlePatternHeatmapSelection = (key, event) =>
    {
        verifyCookies();
        for(let i = 0; i < this.state.patternHeatmapData.length; ++i)
        {
            let dp = this.state.patternHeatmapData[i];
            if(dp.title === key)
            {
                this.setState({selectedHeatmap : dp});
                break;
            }
        }
    }

    handlePatternNameChange = (e) => 
    {
        verifyCookies();
        this.setState({
            [e.currentTarget.id] : e.currentTarget.value,
            disableAddPatterns : e.currentTarget.value === ''
        });
    }

    handleHourChange = (e) =>
    {
        verifyCookies();
        this.setState({
            timeRange : {
                ...this.state.timeRange,
                [e.currentTarget.id] : e.currentTarget.value
            }
        })
    }


    handleAddPattern = (e) =>
    {
        console.log('Adding pattern!');
        verifyCookies();

        //Copies out the pattern heatmap data that exists currently
        let newHeatMapData = [...this.state.patternHeatmapData];


        console.log(newHeatMapData);
        //A web call should be made here in order to get the information 
        //for a new datum body

        //This initializes the headers for the user to what we have in the
        //properties. It also puts the authentication info there as well. 
        let header_config = {
            headers : {
                Auth : Cookies.get('Auth'),
                Username : Cookies.get('Username'),
                user : this.props.user
            }
        }
        let patternRequest = {
            "title" : this.state.patternName,
            "type" : this.state.selectedPatternType,
            "event1" : this.state.selectedEvent1,
            "event2" : this.state.selectedEvent2
        };
        if (this.state.selectedPatternType == "Conditional Sequential"){
            patternRequest.range = [this.state.timeRange.beginHour, this.state.timeRange.endHour];
        }
        axios.post(`https://${config.apiurl}:${config.port}/patterns/create`, patternRequest, header_config)
        .then((created_pattern_details) => {
            console.log(created_pattern_details);
            let newDatum = created_pattern_details.data;
            //ALL CODE THAT SHOULD BE DONE AFTER THE WEB CALL SHOULD BE PUT HERE
            //AFTER GETTING THE RESULT YOU CAN CREATE A DATUM TO ADD TO THE HEATMAP 
            //DATA LIST

            //THE LOGIC BELOW SHOULD GIVE YOU A GOOD ROADMAP FOR DEALING WITH THE PATTERN
            //ADDITION LOGIC

            //THE SET STATE COMMAND SHOULD BE IN HERE AS WELL. 
            if(this.state.selectedPatternType === 'Conditional Sequential')
            {
                newDatum.beginHour = this.state.timeRange.beginHour;
                newDatum.endHour = this.state.timeRange.endHour;
            }

            //Adding our new heatmap data body
            newHeatMapData.push(newDatum);

            this.props.patternOperations.push(newDatum);
            //This sets the new state and resets the pattern addition form
            this.setState({
                patternHeatmapData : newHeatMapData,
                patternName : '',
                disableAddPatterns : true,
                timeRange : {
                    ...this.state.timeRange,
                    beginHour : 0,
                    endHour : 0
                }
            });
            this.props.onRefreshEvents();

        })
        .catch((error) => {
            //DEAL WITH AN ERROR IN HERE.
            alert('Pattern creation failed!');

        })
        /**/
        
        //The code below is meant to act as dummy code for the logic of adding a pattern
        //Some similar form of this should go into the then body after the axios POST call
        // let newDatum = {
        //     title : this.state.patternName,
        //     xValues : ['A', 'B', 'C', 'D', 'E'],
        //     yValues : ['W', 'X', 'Y', 'Z'],
        //     zValues : [
        //         [0.00, 0.00, 0.75, 0.75, 0.00],
        //         [0.00, 0.00, 0.75, 0.75, 0.00],
        //         [0.75, 0.75, 0.75, 0.75, 0.75],
        //         [0.00, 0.00, 0.00, 0.75, 0.00]
        //     ],
        //     type : this.state.selectedPatternType,
        //     event1 : this.state.selectedEvent1,
        //     event2 : this.state.selectedEvent2
        // };

        
    }

    renderPatternNameText()
    {
        return (
            <Form.Row>
                <Col>Pattern Name:</Col>
                <Col>
                    <Form.Control type="text" id="patternName" required size="sm" value={this.state.patternName} onChange={e => this.handlePatternNameChange(e)}/>
                </Col>
            </Form.Row>
        )
    }

    renderPatternList()
    {
        let i = 0;
        return (
            <Table striped bordered hover size = 'sm' variant = 'dark'>
                <thead>
                    <tr>
                    <th>Number</th>
                    <th>Pattern Name</th>
                    <th>Event 1</th>
                    <th>Event 2</th>
                    <th>Operation</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.patternHeatmapData.map((doc) => {
                        i += 1;
                        if(doc.type === 'Concurrent')
                        {
                            return (
                                <tr key = {`${doc.title}-table`}>
                                    <td>{i}</td>
                                    <td>{doc.title}</td>
                                    <td>{doc.event1}</td>
                                    <td>{doc.event2}</td>
                                    <td>Conc.</td>
                                </tr>
                            );
                        }
                        else
                        {
                            return (
                                <tr key = {`${doc.title}-table`}>
                                    <td>{i}</td>
                                    <td>{doc.title}</td>
                                    <td>{doc.event1}</td>
                                    <td>{doc.event2}</td>
                                    <td>{'\u03B4'}<sub>({doc.beginHour}, {doc.endHour})</sub></td>
                                </tr>
                            );
                        }
                    })}
                </tbody>
            </Table>
        );
    }

    renderPatternDropDown()
    {
        return (
            <Form.Row>
                <Col>
                    Event 1:
                </Col>
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            {this.state.selectedEvent1}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {this.getEventsFromDataSet().map((value) => {
                                return (<Dropdown.Item key = {value} eventKey = {value} onSelect = {this.handleDropDownSelect1}>{value}</Dropdown.Item>)
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col>
                    Event 2:
                </Col>
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            {this.state.selectedEvent2}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {this.getEventsFromDataSet().map((value) => {
                                return (<Dropdown.Item key = {value} eventKey = {value} onSelect = {this.handleDropDownSelect2}>{value}</Dropdown.Item>)
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Form.Row>
        );
    }

    renderEventTypeDropDown()
    {
        return (
            <Form.Row>
                <Col>
                    Operation Type:
                </Col>
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            {this.state.selectedPatternType}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {this.state.patternTypes.map((value) => {
                                return (<Dropdown.Item key = {value} eventKey = {value} onSelect = {this.handlePatternTypeSelect}>{value}</Dropdown.Item>)
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col/>
            </Form.Row>
        );
    }

    renderTimeRange()
    {
        return (
            <Form.Row>
                <Col>Begin:</Col>
                <Col>
                    <Form.Control type="text" id="beginHour" required size="sm" value={this.state.timeRange.beginHour} onChange={e => this.handleHourChange(e)}/>
                    {(isNaN(this.state.timeRange.beginHour) || isNaN(this.state.timeRange.endHour)) &&
                        (<Form.Text className = 'text-danger'>The hours need to be numeric!</Form.Text>)
                    }
                    
                </Col>
                <Col>h</Col>
                <Col>End:</Col>
                <Col>
                    <Form.Control type="text" id="endHour" required size="sm" value={this.state.timeRange.endHour} onChange={e => this.handleHourChange(e)}/>
                    {this.state.timeRange.beginHour >= this.state.timeRange.endHour &&
                        (<Form.Text className = 'text-danger'>The beginHour is GTE endHour!</Form.Text>)
                    }
                </Col>
                <Col>h</Col>
            </Form.Row>
        );
    }

    renderPatternSelectionDropDown()
    {
        return (
            <Dropdown>
                <Dropdown.Toggle variant = "primary" id = "dropdown-basic">
                    {(this.state.selectedHeatmap)?this.state.selectedHeatmap.title:null}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {this.state.patternHeatmapData.map((value) => {
                        value = value.title;
                        let key = `${value}-dropdown`
                        return (<Dropdown.Item key = {key} eventKey = {value} onSelect = {this.handlePatternHeatmapSelection}>{value}</Dropdown.Item>)
                    })}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    render()
    {
        verifyCookies();
        console.log(this.props);
        return (
            <div>
                <div
                style = {{
                    textAlign: "center",
                    width: "65%",

                }}
                >
                    {this.renderPatternSelectionDropDown()}
                </div>
                <div>
                    {(this.state.selectedHeatmap)?(
                    <Plot
                    data = {[{
                        x : this.state.selectedHeatmap.xValues,
                        y : this.state.selectedHeatmap.yValues,
                        z : this.state.selectedHeatmap.zValues,
                        type: 'heatmap',
                        colorscale: colorscaleValue,
                        showscale: false
                    }]}
                    layout = {{
                        title: this.state.selectedHeatmap.title,
                        annotations: [],
                        xaxis: {
                            ticks: '',
                            side: 'bottom',
                            title: {
                                text : this.state.selectedHeatmap.event2
                            }
                        },
                        yaxis: {
                            ticks: '',
                            ticksuffix: ' ',
                            width: "65%",
                            height: "65%",
                            autosize: false,
                            title: {
                                text : this.state.selectedHeatmap.event1
                            },
                        }
                    }}
                    />):null
                }
                </div>
                
                {/* This loads up the concurrent pattern adder styling */}
                {this.state.selectedPatternType === 'Concurrent' &&
                    (
                        <div className = "pattern_form_concurrent shadow-lg">
                            <Form>
                                <Form.Row>
                                    <h1>Pattern Creation</h1>
                                </Form.Row>
                                {this.renderPatternNameText()}
                                <br/>
                                {this.renderPatternDropDown()}
                                <br/>
                                {this.renderEventTypeDropDown()}
                                <br/>
                            </Form>
                            <Button variant="primary" onClick = {this.handleAddPattern} disabled = {this.state.disableAddPatterns}>Add Pattern</Button>
                        </div>
                    )
                }
                
                <div className = 'pattern_list'>
                    {this.renderPatternList()}
                </div>

                {/* This adds the error free conditional sequential pattern selector */}
                {this.state.selectedPatternType === 'Conditional Sequential' &&
                    (
                        <div className = "pattern_form_cond_seq shadow-lg">
                            <Form>
                                <Form.Row>
                                    <h1>Pattern Creation</h1>
                                </Form.Row>
                                {this.renderPatternNameText()}
                                <br/>
                                {this.renderPatternDropDown()}
                                <br/>
                                {this.renderEventTypeDropDown()}
                                <br/>
                                {this.renderTimeRange()}
                                <br/>
                            </Form>
                            <Button variant="primary" onClick = {this.handleAddPattern} disabled = {this.state.disableAddPatterns}>Add Pattern</Button>
                        </div>
                    )
                }

                {/* If the cond seq moadl has an error on it we render a larger box instead */}
                {this.state.selectedPatternType === 'Conditional Sequential' &&
                 (this.state.timeRange.beginHour >= this.state.timeRange.endHour ||
                 (isNaN(this.state.timeRange.beginHour) || isNaN(this.state.timeRange.endHour))) &&
                    (
                        <div className = "pattern_form_cond_seq_error shadow-lg">
                            <Form>
                                <Form.Row>
                                    <h1>Pattern Creation</h1>
                                </Form.Row>
                                {this.renderPatternNameText()}
                                <br/>
                                {this.renderPatternDropDown()}
                                <br/>
                                {this.renderEventTypeDropDown()}
                                <br/>
                                {this.renderTimeRange()}
                                <br/>
                            </Form>
                            <Button variant="primary" onClick = {this.handleAddPattern} disabled = {true}>Add Pattern</Button>
                        </div>
                    )
                }
                
            </div>
        );
    }

}

export default Patterns;