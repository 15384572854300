import React, {Component} from 'react';
import './index.css';
// import './LandingPage.css';

// import Tabs from './Tabs';
import LandingPage from './LandingPage';
import Visuals from './VisualsPage';
import EventOperations from './EventOperations';
import Patterns from './PatternOperations';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Cookies from 'js-cookie';
import { Button } from 'react-bootstrap';

const { v4 } = require('uuid');

var moment = require('moment-timezone');

require('./styles.css');

function verifyCookies()
{
    if (Cookies.get('Auth') === undefined || Cookies.get('Username') === undefined)
    {
        console.error('Not logged in!');
        window.location.reload();
    }
    else
    {
        var in10Minutes = 1 / 144;
        Cookies.set('Auth', Cookies.get('Auth'), {expires : in10Minutes});
        Cookies.set('Username', Cookies.get('Username'), {expires : in10Minutes});
    }
}

class HomePage extends Component
{
    constructor(props)
    {
        super(props);
        console.log('In the Home Page constructor');
        this.state = {
            currentActiveTab : "Select Events",
            new_database : new Set(),
            timezone : "",
            user : "",
            disableOtherTabs : true,
            data : [],
            patterns : [],
            dataTransformations: [],
            eventOperations: [],
            patternEventSelections: [],
            visualsUUID : v4()
        }
        this.handleDatabaseAddition = this.handleDatabaseAddition.bind(this);
    }

    handleDataRecieved = (data) => {
        verifyCookies();
        console.log(JSON.stringify(data.data.events, undefined, 4));

        let eventKeys = Object.keys(data.data.events);
        let tz = "";
        let user = "";

        let datastreams = data.data.datastreams.slice();

        this.setState({
            data: datastreams
        });

        this.state.new_database.clear();

        eventKeys.forEach((key) => {
            console.log(`This is the key from the data ${key}`);
            let events = data.data.events[key];
            events.forEach((event_datum) => {
                //2020-02-21 09:48:37 +0000
                let x = event_datum.start_time.split(' ');
                console.log(x);
                let start_date = moment(`${x[0]} ${x[1]} ${x[2]}`, 'YYYY-MM-DD HH:mm:ss Z', true).utcOffset(x[2]);

                x = event_datum.end_time.split(' ');
                let end_date = moment(`${x[0]} ${x[1]} ${x[2]}`, 'YYYY-MM-DD HH:mm:ss Z', true).utcOffset(x[2]);
                tz = x[2];

                console.log(`HOMEPAGE: start time string : ${event_datum.start_time}\nand the end time string : ${event_datum.end_time}`);
                console.log(`HOMEPAGE: start date: ${start_date}\nand my end date: ${end_date}`);

                //Build and add the data recieved to our database
                let data_body = {
                    name : event_datum.event_name,
                    type : event_datum.event_type,
                    start_date : start_date,
                    end_date : end_date,
                };

                this.state.new_database.add(data_body);

                //Set the user if we dont have one yet. 
                if(user === '')
                {
                    user = event_datum.userID;
                }
            });
        })

        console.log(`The new database has these many records ${this.state.new_database.size}`);
        console.log(`this is the user id we are dealing with ${user}`);

        this.setState({
            currentActiveTab : "Visualizations",
            disableOtherTabs : false,
            visualsUUID : v4(),
            timezone : tz,
            user : user
        });
    }

    handleDatabaseAddition(){
        this.setState({
            visualsUUID: v4()
        });
    }

    handleTabSelect = (event) => {
        verifyCookies();
        console.log(event);
        this.setState({currentActiveTab : event})
    }

    handleLogout = (event) => {
        verifyCookies();
        Cookies.remove('Auth');
        Cookies.remove('Username');
        this.props.history.push('/');
    }

    render()
    {
        console.log("Overall state");
        console.log(JSON.stringify(this.state), undefined, 4);
        if(this.state.disableOtherTabs)
        {
            return (
                <div>
                    <div>
                        <h1>Event Mining Dashboard</h1>
                        <Tabs activeKey = {this.state.currentActiveTab} onSelect = {this.handleTabSelect}>
                            <Tab label = "Select Events" eventKey = "Select Events" title = "Select Events">
                                <LandingPage onDataRecieved = {this.handleDataRecieved} onRefreshEvents = {this.handleDatabaseAddition}/>
                            </Tab>
                            <Tab label = "Visualizations" eventKey = "Visualizations" title = "Visualizations" disabled = {this.state.disableOtherTabs}>
                                HAI
                            </Tab>
                            <Tab label = "Event Operations" eventKey = "Event Operations" title = "Event Operations" disabled = {this.state.disableOtherTabs}>
                                HAI
                            </Tab>
                            <Tab label = "Pattern Operations" eventKey = "Pattern Operations" title = "Pattern Operations" disabled = {this.state.disableOtherTabs}>
                                HAI
                            </Tab>
                        </Tabs>
                    </div>
                    <div className = 'logout_button'>
                        <Button variant="primary" onClick = {this.handleLogout}>Logout</Button>
                    </div>
                </div>
            );
        }
        else
        {
            return (
                <div>
                    <div>
                        <h1>Event Mining Dashboard</h1>
                        <Tabs activeKey = {this.state.currentActiveTab} onSelect = {this.handleTabSelect}>
                            <Tab label = "Select Events" eventKey = "Select Events" title = "Select Events">
                                <LandingPage onDataRecieved = {this.handleDataRecieved} onRefreshEvents = {this.handleDatabaseAddition}/>
                            </Tab>
                            <Tab label = "Visualizations" eventKey = "Visualizations" title = "Visualizations" disabled = {this.state.disableOtherTabs}>
                                <Visuals key = {this.state.visualsUUID} new_database = {this.state.new_database} user = {this.state.user} datastreams = {this.state.data}
                                onRefreshEvents = {this.handleDatabaseAddition} timezone = {this.state.timezone}/>
                            </Tab>
                            <Tab label="Event Operations" eventKey = "Event Operations" title = "Event Operations" disabled = {this.state.disableOtherTabs}>
                                <EventOperations key = {this.state.visualsUUID} new_database = {this.state.new_database} user = {this.state.user} datastreams = {this.state.data}
                                onRefreshEvents = {this.handleDatabaseAddition} dataTransformations = {this.state.dataTransformations} eventOperations = {this.state.eventOperations}
                                patternOperations={this.state.patterns} patternSelectedEvents={this.state.patternEventSelections}/>
                            </Tab>
                            <Tab label = "Pattern Operations" eventKey = "Pattern Operations" title = "Pattern Operations" disabled = {this.state.disableOtherTabs}>
                                <Patterns key = {this.state.visualsUUID} new_database = {this.state.new_database} user = {this.state.user} onRefreshEvents = {this.handleDatabaseAddition}
                                patternOperations={this.state.patterns}/>
                            </Tab>
                        </Tabs>
                    </div>
                    <div className = 'logout_button'>
                        <Button variant="primary" onClick = {this.handleLogout}>Logout</Button>
                    </div>
                </div>
            );
        }
        
    }
}

export default HomePage;