import React, { Component } from 'react'
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./components"; // example render components - source below
import { subDays, startOfToday, format, parse, endOfToday } from "date-fns";

import { scaleTime } from "d3-scale";

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Plot from 'react-plotly.js';

import Dropdown from 'react-bootstrap/Dropdown';
import { Button } from 'react-bootstrap';

import { Chart } from "react-google-charts";
import './index.css';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';


var moment = require('moment-timezone');

const sliderStyle = {
    position: "relative",
    width: "100%"
};

function formatTick(ms) {
    return format(new Date(ms), "MMM dd yy");
}

function verifyCookies()
{
    if (Cookies.get('Auth') === undefined || Cookies.get('Username') === undefined)
    {
        console.error('Not logged in!');
        window.location.reload();
    }
    else
    {
        var in10Minutes = 1 / 144;
        Cookies.set('Auth', Cookies.get('Auth'), {expires : in10Minutes});
        Cookies.set('Username', Cookies.get('Username'), {expires : in10Minutes});
    }
}

const halfHour = 1000 * 60 * 30;

const data_header = [
    { type: 'string', id: 'EventName' },
    { type: 'string', id: 'EventType' },
    { type: 'date', id: 'Start' },
    { type: 'date', id: 'End' },
];

const mins_in_day = 24 * 60;

class Visuals extends Component 
{
    static propTypes = {
        new_database : PropTypes.object.isRequired,
        timezone : PropTypes.string.isRequired,
        user : PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {};

        this.newLoadData(this.props.new_database);
    }

    async newLoadData(new_database)
    {
        verifyCookies();
        console.log(`We are in the new load data`);
        let minDate = null; let maxDate = null;
        let all_data = [];
        let possibleEventNames = new Set();
        let possibleEventTypes = new Set();
        
        let firstType = "";
        let thetas = []; let radiuses = []; let custom = []; let r = 1; 
        let name_to_index = {}; let current_name_index = 0;
        let name_to_type = {};

        new_database.forEach((doc) => {
            // console.log(JSON.stringify(doc, undefined, 4));
            let start_date = doc.start_date; let end_date = doc.end_date;
            // console.log(`VISUALSPAGE: This is my start date: ${start_date}\nand my end date: ${end_date} for event: ${doc.name} of type: ${doc.type}`);

            if(minDate === null || start_date < minDate)
            {
                minDate = start_date;
            }
            if(maxDate === null || end_date > maxDate)
            {
                maxDate = end_date;
            }

            possibleEventNames.add(doc.name);
            possibleEventTypes.add(doc.type);
            name_to_type[doc.name.toLowerCase()] = doc.type;

            all_data.push([doc.name, doc.type, start_date, end_date]);

            if(firstType === "" || firstType === doc.type)
            {
                firstType = doc.type;
                let name_index = null;

                if(Object.keys(name_to_index).includes(doc.name))
                {
                    name_index = name_to_index[doc.name];
                }
                else
                {
                    name_to_index[doc.name] = current_name_index;
                    name_index = current_name_index;
                    ++current_name_index;
                    thetas.push([]); radiuses.push([]); custom.push([]);
                }

                let theta1 = ((start_date.hours() * 60) + start_date.minutes()) / mins_in_day * 360;
                let theta2 = ((end_date.hours() * 60) + end_date.minutes()) / mins_in_day * 360;

                if(theta2 < theta1)
                {
                    theta2 += 360;
                }

                thetas[name_index].push(theta1);
                radiuses[name_index].push(r);
                let min = ((theta1 % 360) / 360) * mins_in_day;
                custom[name_index].push([start_date, end_date, Math.floor(min / 60), Math.floor(min % 60), doc.name]);

                for (let a = theta1; a < theta2; a += 0.75)
                {
                    thetas[name_index].push(a);
                    radiuses[name_index].push(r);
                    min = ((a % 360) / 360) * mins_in_day;
                    custom[name_index].push([start_date, end_date, Math.floor(min / 60), Math.floor(min % 60), doc.name]);
                }
                
                thetas[name_index].push(theta2);
                radiuses[name_index].push(r);
                min = ((theta2 % 360) / 360) * mins_in_day;
                custom[name_index].push([start_date, end_date, Math.floor(min / 60), Math.floor(min % 60), doc.name]);

                r += 2;
            }
        });

        all_data.unshift(data_header);

        console.log(`This is the min date ${minDate}`);
        console.log(`This is the max date ${maxDate}`);
        console.log(`This is the end of new LOAD DATA`);

        console.log(`This is our name to type ${JSON.stringify(name_to_type, undefined, 4)}`);
        possibleEventNames = [...possibleEventNames];

        let listToSort = [];
        possibleEventNames.forEach((val) => {listToSort.push([val, name_to_type[val.toLowerCase()]])});
        console.log(`list to sort before`);
        listToSort.forEach((doc) => {console.log(doc)});

        console.log(`NEW LOAD DATA: Possible event types before sort ${possibleEventNames}`);

        listToSort.sort((a, b) => {
            
            let atype = a[1]; let btype = b[1];
            let aname = a[0].toLowerCase(); let bname = b[0].toLowerCase();

            console.log(`a : ${aname} atype : ${atype}, b : ${bname}, btype : ${btype}`);

            if(atype !== btype)
            {
                console.log('Inequal types!');
                return atype > btype ? 1 : -1;
            }
            else
            {
                if(aname === bname)
                {
                    return 0;
                }
                return aname > bname ? -1 : 1;
            }
        });

        console.log(`list to sort after`);
        listToSort.forEach((doc) => {console.log(doc)});

        possibleEventNames = [];
        listToSort.forEach((doc) => {possibleEventNames.push(doc[0])});

        console.log(`NEW LOAD DATA: Possible event types after sort ${possibleEventNames}`);
        
        this.state = {
            selected : [minDate, maxDate],
            updated : [minDate, maxDate],
            min : minDate,
            max : maxDate,
            data : all_data,
            possibleEventNames: [...possibleEventNames],
            possibleEventNamesFrozen : [...possibleEventNames],
            filterDataSet : new Set([...possibleEventNames]),
            polarStuff : {
                thetas : thetas,
                radiuses : radiuses,
                custom : custom,
                names : Object.keys(name_to_index),
                eventTypes : [...possibleEventTypes],
                selectedEventType : [...possibleEventTypes][0]
            }
        };

        // console.log(`This state is now this after NEW load data ${JSON.stringify(this.state, undefined, 4)}`);
    }

    generatePolarData(color_array)
    {
        verifyCookies();
        let data = [];

        for (let i = 0; i < this.state.polarStuff.thetas.length; ++i)
        {
            // console.log(`In generate polar data ${this.state.polarStuff.custom[i]}`);
            data.push(
                {
                  type: "scatterpolar",
                  mode: "markers",
                  name: this.state.polarStuff.names[i],
                  r: this.state.polarStuff.radiuses[i],
                  theta: this.state.polarStuff.thetas[i],
                  customdata : this.state.polarStuff.custom[i],
                  marker : {
                      symbol : 'circle',
                      size : 2,
                      maxdisplayed : 10000
                  },
                  line: {
                      color: color_array[i]
                  },
                  hovertemplate : "StartDate: %{customdata[0]}<br>EndDate: %{customdata[1]}<br>TimeOfDay: %{customdata[2]:02d}:%{customdata[3]:02d}<br>Event Name: %{customdata[4]}",
                  hoverlabel : {
                      align : "left"
                  }
                }
            )
        }

        return data;
    }

    newFilterData(startDate, endDate, categories)
    {
        verifyCookies();
        console.log(`NEW FILTER DATA HAS BEEN CALLED with startDate ${startDate}, endDate ${endDate}, and categories [ ${[...categories]} ]`);

        let filteredData = [data_header];
        let possibleEventNamesList = [];
        let possibleEventNamesSet = new Set();
        let name_to_type = {};

        this.props.new_database.forEach((doc) => {

            // console.log(`${JSON.stringify(doc, undefined, 4)}`);

            let start_date = doc.start_date; let end_date = doc.end_date;

            // console.log(`start_date : ${(start_date > startDate)}`);
            // console.log(`end_date : ${end_date < endDate}`)
           
            if((start_date > startDate) && 
            (end_date < endDate) && 
            categories.has(doc.name))
            {
                filteredData.push([doc.type, doc.name, doc.start_date, doc.end_date]);
                
                name_to_type[doc.name.toLowerCase()] = doc.type;
                if(!possibleEventNamesSet.has(doc.name))
                {
                    possibleEventNamesList.push(doc.name);
                    possibleEventNamesSet.add(doc.name);
                }    
            }
        });
        console.log(`NEW FILTER DATA: Possible event types before sort ${possibleEventNamesList}`);

        console.log(`This is our name to type ${JSON.stringify(name_to_type, undefined, 4)}`);
        possibleEventNamesList = [...possibleEventNamesList];

        let listToSort = [];
        possibleEventNamesList.forEach((val) => {listToSort.push([val, name_to_type[val.toLowerCase()]])});
        console.log(`list to sort before`);
        listToSort.forEach((doc) => {console.log(doc)});

        console.log(`NEW FILTER DATA: Possible event types before sort ${possibleEventNamesList}`);

        listToSort.sort((a, b) => {
            
            let atype = a[1]; let btype = b[1];
            let aname = a[0].toLowerCase(); let bname = b[0].toLowerCase();

            console.log(`a : ${aname} atype : ${atype}, b : ${bname}, btype : ${btype}`);

            if(atype !== btype)
            {
                console.log('Inequal types!');
                return atype > btype ? 1 : -1;
            }
            else
            {
                if(aname === bname)
                {
                    return 0;
                }
                return aname > bname ? -1 : 1;
            }
        });

        console.log(`list to sort after`);
        listToSort.forEach((doc) => {console.log(doc)});

        possibleEventNamesList = [];
        listToSort.forEach((doc) => {possibleEventNamesList.push(doc[0])});

        console.log(`NEW FILTER DATA: Possible event types after sort ${possibleEventNamesList}`);
        return [filteredData, possibleEventNamesList];
    }
    
    onChange = ([ms1, ms2]) => {
        let startDate = moment(ms1).utcOffset(this.props.timezone);
        let endDate = moment(ms2).utcOffset(this.props.timezone);

        // console.log(`In the CHANGE this is the start date ${startDate} and the end date ${endDate}`);
        // console.log(`end date is GT start date ${endDate > startDate}`);

        let result = this.newFilterData(startDate, endDate, this.state.filterDataSet);
        // console.log(`This is the result length ${result.length}`);

        this.setState({
            selected : [startDate, endDate],
            data : result[0],
            possibleEventNames : result[1],
        },
        () => {
            console.log('FINISHED UPDATING DATA');
            console.log(result);
        });
    };

    handleCategoryCheckBoxChange(event, datum)
    {
        verifyCookies();
        if(event.target.checked)
        {
            this.state.filterDataSet.add(datum);
        }
        else
        {
            this.state.filterDataSet.delete(datum);
        }
        console.log(datum);
        console.log(this.state.filterDataSet);
    }

    handleApplyFilter = (e) =>
    {
        verifyCookies();
        let result = this.newFilterData(this.state.selected[0], this.state.selected[1], this.state.filterDataSet);
        console.log(`This is the result length ${result.length}`);

        this.setState({
            data : result[0],
            possibleEventNames : result[1],
        },
        () => {
            console.log('FINISHED UPDATING DATA FILTER!');
            console.log(this.state);
        });
    }

    newHandleDropdownSelect = (key, event) => 
    {
        verifyCookies();
        console.log(key);
        console.log(event);

        if(key === this.state.polarStuff.selectedEventType)
        {
            return;
        }

        let thetas = [];
        let radiuses = [];
        let custom = [];
        let r = 1;
        let name_to_index = {}; let current_name_index = 0;

        this.props.new_database.forEach((doc) => {
            if(doc.type === key)
            {
                let start_date = doc.start_date;
                let end_date = doc.end_date;

                let name_index = null;

                if(Object.keys(name_to_index).includes(doc.name))
                {
                    name_index = name_to_index[doc.name];
                }
                else
                {
                    name_to_index[doc.name] = current_name_index;
                    name_index = current_name_index;
                    ++current_name_index;
                    thetas.push([]); radiuses.push([]); custom.push([]);
                }

                let theta1 = ((start_date.hours() * 60) + start_date.minutes()) / mins_in_day * 360;
                let theta2 = ((end_date.hours() * 60) + end_date.minutes()) / mins_in_day * 360;

                if(theta2 < theta1)
                {
                    theta2 += 360;
                }

                thetas[name_index].push(theta1);
                radiuses[name_index].push(r);
                let min = ((theta1 % 360) / 360) * mins_in_day;
                custom[name_index].push([start_date, end_date, Math.floor(min / 60), Math.floor(min % 60), doc.name]);

                for (let a = theta1; a < theta2; a += 0.75)
                {
                    thetas[name_index].push(a);
                    radiuses[name_index].push(r);
                    min = ((a % 360) / 360) * mins_in_day;
                    custom[name_index].push([start_date, end_date, Math.floor(min / 60), Math.floor(min % 60), doc.name]);
                }
                
                thetas[name_index].push(theta2);
                radiuses[name_index].push(r);
                min = ((theta2 % 360) / 360) * mins_in_day;
                console.log([start_date, end_date, Math.floor(min / 60), Math.floor(min % 60), doc.name]);
                custom[name_index].push([start_date, end_date, Math.floor(min / 60), Math.floor(min % 60), doc.name]);

                r += 2;
            }
        });

        this.setState({
            polarStuff : {
                ...this.state.polarStuff, 
                selectedEventType : key,
                thetas : thetas,
                radiuses : radiuses,
                custom : custom,
                names : Object.keys(name_to_index),
            }
        });
    }

    handleDropdownSelect = (key, event) => 
    {
        verifyCookies();
        console.log(key);
        console.log(event);

        if(key === this.state.polarStuff.selectedEventType)
        {
            return;
        }

        let thetas = [];
        let radiuses = [];
        let custom = [];
        let r = 1;
        let name_to_index = {}; let current_name_index = 0;

        this.props.database.find({
            type : key
        }).sort({type : 1}).exec((err, docs) => {
            console.log(docs.length);
            docs.forEach((doc) => {
                let start_date = doc.start_date;
                let end_date = doc.end_date;

                let name_index = null;

                if(Object.keys(name_to_index).includes(doc.name))
                {
                    name_index = name_to_index[doc.name];
                }
                else
                {
                    name_to_index[doc.name] = current_name_index;
                    name_index = current_name_index;
                    ++current_name_index;
                    thetas.push([]); radiuses.push([]); custom.push([]);
                }

                let theta1 = ((start_date.hours() * 60) + start_date.minutes()) / mins_in_day * 360;
                let theta2 = ((end_date.hours() * 60) + end_date.minutes()) / mins_in_day * 360;

                if(theta2 < theta1)
                {
                    theta2 += 360;
                }

                thetas[name_index].push(theta1);
                radiuses[name_index].push(r);
                let min = ((theta1 % 360) / 360) * mins_in_day;
                custom[name_index].push([start_date, end_date, Math.floor(min / 60), Math.floor(min % 60)]);

                for (let a = theta1; a < theta2; a += 0.75)
                {
                    thetas[name_index].push(a);
                    radiuses[name_index].push(r);
                    min = ((a % 360) / 360) * mins_in_day;
                    custom[name_index].push([start_date, end_date, Math.floor(min / 60), Math.floor(min % 60)]);
                }
                
                thetas[name_index].push(theta2);
                radiuses[name_index].push(r);
                min = ((theta2 % 360) / 360) * mins_in_day;
                custom[name_index].push([start_date, end_date, Math.floor(min / 60), Math.floor(min % 60)]);

                r += 2;
            });

            this.setState({
                polarStuff : {
                    ...this.state.polarStuff, 
                    selectedEventType : key,
                    thetas : thetas,
                    radiuses : radiuses,
                    custom : custom,
                    names : Object.keys(name_to_index),
            }})
        });
    }

    renderDateTime(date, header) 
    {
        verifyCookies();
        console.log(`This is our date: ${date[0]}`);
        let date1 = `${date[0]}`;
        let date2 = `${date[1]}`;
        return (
            <div
            style={{
                width: "100%",
                textAlign: "center",
                fontFamily: "Arial",
                margin : 0,
                // border: "3px solid rgb(115, 0, 223)"
            }}
            >
            <b>{header[0]} - {header[1]}</b>
            <div style={{ fontSize: 12, marginBottom : -1 }}>{date1} to {date2}</div>
            </div>
        );
    }

    renderSmallSquare(squareColor)
    {
        verifyCookies();
        let styling = {
            background: squareColor,
            border: '1px solid #999',
            float: 'left',
            'height': '10px',
            'width': '20px',
            'marginTop': '-1px',
            marginRight : '5px',
            marginLeft : '5px',
            padding: 0,
            'textAlign': 'center',
        };
        return (
            <div>
                <button style = {styling}></button>
            </div>
        )
        
    }

    renderLegend(colors, events)
    {
        verifyCookies();
        let groups = [];

        for (let i = 0; i < events.length; ++i)
        {
            // groups.push(<div>{this.renderSmallSquare(colors[i])}{events[i]}</div>);
            groups.push(
                <FormControlLabel
                    value = "end"
                    control = {this.renderSmallSquare(colors[i])}
                    label = {events[i]}
                    labelPlacement = "end"
                    key = {events[i]}
                />
            );
        }
        return (
            <div className = "legend">
            <FormControl component = "fieldset">
                <FormLabel component = "legend">LEGEND</FormLabel>
                <FormGroup aria-label = "position">
                {groups}
                </FormGroup>
            </FormControl>
            </div>
            
        );
    }

    renderPolarSelectionDropdown()
    {
        verifyCookies();
        return (
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {this.state.polarStuff.selectedEventType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {this.state.polarStuff.eventTypes.map((value) => {
                        return (<Dropdown.Item key = {value} eventKey = {value} onSelect = {this.newHandleDropdownSelect}>{value}</Dropdown.Item>)
                    })}
                    
                </Dropdown.Menu>
            </Dropdown>
        );
        
    }

    render() 
    {
        verifyCookies();
        console.log(this.state);
        console.log("Visulaisations tab");
        console.log(this.props.new_database);
        // console.log(`This is the state in the render ${JSON.stringify(this.state, undefined, 4)}`);

        if (this.state === null || this.state === {} || Object.keys(this.state).length === 0)
        {
            return <div>Loading...</div>
        }

        const { min, max, selected, updated } = this.state;
        

        let color_array = ['#4B0082', '#3CB371', '#800000',
                           '#9932CC', '#BDB76B', '#FFD700',
                           '#FF1493', '#FA8072', '#F4A460',
                           '#996633', '#00ffff', '#ff00ff',
                          ];

        const dateTicks = scaleTime()
        .domain([min, max])
        .ticks(10)
        .map((d) => +d);
        return (
        <div>
            <div>
                {this.renderDateTime(selected, ["Selected Date 1", "Selected Date 2"])}
                <div style={{ 
                    margin: "1%",
                    marginLeft : "5%",
                    marginRight : "5%",
                    height: 30, 
                    width: "90%",  
                    // border: "3px solid rgb(0, 0, 223)",
                    'textAlign': 'center'
                }}>
                <Slider
                    mode={1}
                    step={halfHour*2}
                    domain={[+min, +max]}
                    rootStyle={sliderStyle}
                    // onUpdate={this.onUpdate}
                    onChange={this.onChange}
                    values={[+selected[0], +selected[1]]}
                >
                    <Rail>
                    {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                    </Rail>
                    <Handles>
                    {({ handles, getHandleProps }) => (
                        <div>
                        {handles.map((handle) => (
                            <Handle
                            key={handle.id}
                            handle={handle}
                            domain={[+min, +max]}
                            getHandleProps={getHandleProps}
                            />
                        ))}
                        </div>
                    )}
                    </Handles>
                    <Tracks right={false} left={false}>
                    {({ tracks, getTrackProps }) => (
                        <div>
                        {tracks.map(({ id, source, target }) => (
                            <Track
                            key={id}
                            source={source}
                            target={target}
                            getTrackProps={getTrackProps}
                            />
                        ))}
                        </div>
                    )}
                    </Tracks>
                    <Ticks values={dateTicks}>
                    {({ ticks }) => (
                        <div>
                        {ticks.map((tick) => (
                            <Tick
                            key={tick.id}
                            tick={tick}
                            count={ticks.length}
                            format={formatTick}
                            />
                        ))}
                        </div>
                    )}
                    </Ticks>
                </Slider>
                </div>
            </div>
            

            <div>
            <Chart
            width={'100%'}
            height = {'250px'}
            chartType="Timeline"
            loader={<div>Loading Chart</div>}
            data={this.state.data}
            options={{
                colors : color_array,
                timeline: {
                    groupByRowLabel: true,
                    showBarLabels : false
                },
            }}
            rootProps={{ 'data-testid': '4' }}
            />
            </div>
            {this.renderLegend(color_array, this.state.possibleEventNames)}

            <div className = "filter"> 
                <FormControl component = "fieldset">
                    <FormLabel component = "legend">Category Filtering</FormLabel>
                    <FormGroup aria-label = "position">
                        {this.state.possibleEventNamesFrozen.map((datum) => {
                            console.log(`Found possible event: ${datum}`);
                            return (
                                <FormControlLabel
                                    value = "end"
                                    control = {<Checkbox defaultChecked onChange = {(event) => {this.handleCategoryCheckBoxChange(event, datum)}}/>}
                                    label = {datum}
                                    labelPlacement = "end"
                                    key = {datum}
                                />
                            )
                        })}
                        
                    </FormGroup>
                </FormControl>
            </div>
            <div className = "applyfilter">
                <Button type = "submit" className = "btn btn-primary" onClick = {this.handleApplyFilter}>Apply Filter</Button>
                <Button type = "submit" className = "btn btn-primary" onClick = {(e) => {this.newLoadData(this.props.new_database); this.render();}}>Refresh</Button>
            </div>
            {/* <div className = "refreshPage">
                <Button type = "submit" className = "btn btn-primary" onClick = {(e) => {this.newLoadData(this.props.new_database)}}>Refresh</Button>
            </div> */}
            
            <div className = "polarchart">
            <Plot
            data={this.generatePolarData(color_array)}
            layout={ {
                width: "500", 
                height: "500",
                showlegend : true,
                legend : {
                    traceorder : "reversed"
                },
                polar : {
                    angularaxis : {
                        rotation : 90,
                        direction : "clockwise",
                        nticks : 8,
                        ticktext : ['12AM', '3AM', '6AM', '9AM', '12PM', '3PM', '6PM', '9PM'],
                        tickvals : [0, 45, 90, 135, 180, 225, 270, 315],
                        tickmode : 'array',
                        ticklen : 1,
                        tickwidth : 1,
                    },
                    radialaxis : {
                        visible : false
                    }
                },
                title: this.state.polarStuff.selectedEventType + ' Events'
            } }
          />
          </div>

          <div className = 'polarselector'>
              {this.renderPolarSelectionDropdown()}
          </div>
        
        </div>    
        );
    }
}
export default Visuals;