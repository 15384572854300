import React, {Component} from 'react';
import { DateRangePicker } from 'react-date-range';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';

import { Form, Button } from 'react-bootstrap';
import {format} from 'date-fns';

import './index.css';
import axios from 'axios';
import Cookies from 'js-cookie';

//Adding FileUpload component
import FileUpload from './FileUpload';

var config = require('./config.json');

class LandingPage extends Component
{
    static propTypes = {
        onDataRecieved : PropTypes.func.isRequired
    }
    constructor(props)
    {
        super(props);

        this.state = {
            dataStreams : [],
            eventStreams : [],
            users : [],
            chosenEvents : new Set(),
            chosenData : new Set(),
            chosenUser : "",
            selectionRange : {
                startDate: new Date(2020, 0, 1),
                endDate: new Date(2020, 2, 1),
                key: 'selection',
            },
            onDataRecieved : this.props.onDataRecieved,
            submitDisabled : true
        };
        this.handleRefreshData(null);
        
    }

    verifyCookies()
    {
        if (Cookies.get('Auth') === undefined || Cookies.get('Username') === undefined)
        {
            console.error('Not logged in!');
            window.location.reload();
        }
        else
        {
            var in10Minutes = 1 / 144;
            Cookies.set('Auth', Cookies.get('Auth'), {expires : in10Minutes});
            Cookies.set('Username', Cookies.get('Username'), {expires : in10Minutes});
        }
    }

    getUsers()
    {
        this.verifyCookies();
        console.log(`In the GET /users and my Auth is ${Cookies.get('Auth')}, and the Username is ${Cookies.get('Username')}`);

        let header_config = {
            headers : {
                Auth : Cookies.get('Auth'),
                Username : Cookies.get('Username')
            }
        }
        axios.get(`https://${config.apiurl}:${config.port}/users`, header_config)
        .then((users) => {
            let temp_users = [];

            for(let i = 0; i < users['data'].length; ++i)
            {
                temp_users.push(users['data'][i]['label']);
            }

            this.setState({...this.state, users : temp_users});
        })
        .catch((error) => {
            console.error(`Encountered this error while trying to get users ${error}`);
        });
    }
    
    getEvents()
    {
        this.verifyCookies();

        console.log(`In the GET /events and my Auth is ${Cookies.get('Auth')}, and the Username is ${Cookies.get('Username')}`);

        let header_config = {
            headers : {
                Auth : Cookies.get('Auth'),
                Username : Cookies.get('Username')
            }
        }
        axios.get(`https://${config.apiurl}:${config.port}/events`, header_config)
        .then((events) => {
            let temp_events = [];

            for(let i = 0; i < events['data'].length; ++i)
            {
                temp_events.push(events['data'][i]['label']);
            }

            this.setState({...this.state, eventStreams : temp_events});
        })
        .catch((error) => {
            console.error(`Encountered this error while trying to get events ${error}`);
        });
    }

    getData()
    {
        this.verifyCookies();

        console.log(`In the GET /data and my Auth is ${Cookies.get('Auth')}, and the Username is ${Cookies.get('Username')}`);
        
        let header_config = {
            headers : {
                Auth : Cookies.get('Auth'),
                Username : Cookies.get('Username')
            }
        }
        
        axios.get(`https://${config.apiurl}:${config.port}/datastreams`, header_config)
        .then((datastreams) => {
            let temp_data = [];

            for (let i = 0; i < datastreams['data'].length; ++i)
            {
                temp_data.push(datastreams['data'][i]['label']);
            }

            this.setState({...this.state, dataStreams : temp_data});
        })
        .catch((error) => {
            console.error(`Encountered this error while trying to get data ${error}`);
        })
    }

    handleEventCheckBoxChange(event, label)
    {
        this.verifyCookies();
        if(event.target.checked)
        {
            this.state.chosenEvents.add(label);
        }
        else
        {
            this.state.chosenEvents.delete(label);
        }
        this.setState({
            submitDisabled : !this.enableSubmit()
        })
        console.log(this.state.chosenEvents);
    }

    handleDataCheckBoxChange(event, label)
    {
        this.verifyCookies();
        if(event.target.checked)
        {
            this.state.chosenData.add(label);
        }
        else
        {
            this.state.chosenData.delete(label);
        }
        console.log(this.state.chosenData);
    }

    handleUserCheckBoxChange(event, label)
    {
        this.verifyCookies();
        this.setState({...this.state, chosenUser : event.target.value}, 
            () => {
                this.setState({
                    submitDisabled : !this.enableSubmit()}
                )})
    }

    handleRefreshData = (e) =>
    {
        this.getData();
        this.getEvents();
        this.getUsers();
    }

    handleSubmitData = (e) => {
        this.verifyCookies();
        console.log(`In the POST /data-select and my Auth is ${Cookies.get('Auth')}, and the Username is ${Cookies.get('Username')}`);
        
        let header_config = {
            headers : {
                Auth : Cookies.get('Auth'),
                Username : Cookies.get('Username')
            }
        }

        let d = new Date();
        console.log(format(d, "yyyy-MM-dd"));
        let request_body = {
            start_date : format(this.state.selectionRange.startDate, "yyyy-MM-dd"),
            end_date : format(this.state.selectionRange.endDate, "yyyy-MM-dd"),
            participant : this.state.chosenUser,
            events : [...this.state.chosenEvents],
            datastreams : [...this.state.chosenData],
        };

        // console.log(JSON.stringify(request_body, undefined, 4));

        axios.post(`https://${config.apiurl}:${config.port}/data-select`, request_body, header_config)
        .then((data) => {
            // console.log(JSON.stringify(data, undefined, 4));
            this.state.onDataRecieved(data);
        })

    }

    handleSelect(ranges){
        console.log(ranges);
        
        this.setState({...this.state, 
            selectionRange : {
                ...this.state.selectionRange,
                startDate : ranges.selection.startDate,
                endDate : ranges.selection.endDate
            }}, 
            () => {
                console.log(this.state);
                this.setState({
                    submitDisabled : !this.enableSubmit()
                })
            });
    }

    // 8/16 MS - func to get table titles for the file upload dropdown menu
    getTableTitles = () => {
        this.verifyCookies();

        let titles = [];
        let conf = {
            headers : {
                Auth : Cookies.get('Auth'),
                Username : Cookies.get('Username')
            }
        }
        axios.get(`https://${config.apiurl}:${config.port}/tables`, conf)
        .then((tableTitles) => {
            for (let i = 0; i < tableTitles['data']['tables'].length; ++i)
            {
                titles.push(tableTitles['data']['tables'][i]);
            }
        })
        .catch((error) => {
            console.error(`Encountered this error while trying to get table titles ${error}`);
        })

        return titles;
    }

    enableSubmit()
    {
        // console.log(JSON.stringify(this.state, undefined, 4));
        // console.log(this.state.chosenEvents.size !== 0 && this.state.chosenUser !== "" && this.state.selectionRange.startDate !== new Date() && this.state.selectionRange.endDate !== new Date());
        return this.state.chosenEvents.size !== 0 && this.state.chosenUser !== "" && this.state.selectionRange.startDate !== new Date() && this.state.selectionRange.endDate !== new Date();
    }

    render()
    {
        var titles = this.getTableTitles();
        return (
            <div>
                <div className = "data_selection"> 
                    <FormControl component = "fieldset">
                        <FormLabel style = {{color : 'black'}} component = "legend">DATA Stream Selection:</FormLabel>
                        <FormGroup aria-label = "position">
                            {this.state.dataStreams.map((datum) => {
                                return (
                                    <FormControlLabel
                                        value = "end"
                                        control = {<Checkbox onChange = {(event) => {this.handleDataCheckBoxChange(event, datum)}}/>}
                                        label = {datum}
                                        labelPlacement = "end"
                                        key = {datum}
                                    />
                                )
                            })}
                            
                        </FormGroup>
                    </FormControl>
                </div>
                <div className = "event_selection"> 
                    <FormControl component = "fieldset">
                        <FormLabel style = {{color : 'black'}} component = "legend">EVENT Stream Selection:</FormLabel>
                        <FormGroup aria-label = "position">
                            {this.state.eventStreams.map((datum) => {
                                return (
                                    <FormControlLabel
                                        value = "end"
                                        control = {<Checkbox onChange = {(event) => {this.handleEventCheckBoxChange(event, datum)}}/>}
                                        label = {datum}
                                        labelPlacement = "end"
                                        key = {datum}
                                    />
                                )
                            })}
                            
                        </FormGroup>
                    </FormControl>
                </div>
                <div className = "user_selection shadow-lg">
                <FormControl component = "fieldset">
                        <FormLabel style = {{color : 'black'}} component = "legend">USER Stream Selection:</FormLabel>
                        <RadioGroup aria-label = "position">
                            {this.state.users.map((datum) => {
                                return (
                                    <FormControlLabel
                                        value = {datum}
                                        control = {<Radio onChange = {(event) => {this.handleUserCheckBoxChange(event, datum)}}/>}
                                        label = {datum}
                                        labelPlacement = "end"
                                        key = {datum}
                                    />
                                )
                            })}
                            
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className = "refresh_data_button">
                    <div>
                        <DateRangePicker ranges={[this.state.selectionRange]} onChange={(ranges) => this.handleSelect(ranges)}/>
                    </div>
                    <div>
                        <Button onClick = {this.handleRefreshData} type = "submit" className = "btn btn-primary">Refresh Sources</Button>
                    </div>
                    <div className = 'submit_data_button'>
                        <Button onClick = {this.handleSubmitData} type = "submit" className = "btn btn-success" disabled = {this.state.submitDisabled}>Submit Data Selection</Button>                    
                    </div>
                    <div>
                        <FileUpload fileType=".csv" titles = {titles}></FileUpload>
                    </div>
                    <div className = 'xml_uploader'>
                        <FileUpload fileType = ".xml" titles = {titles}></FileUpload>
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingPage;