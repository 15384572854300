import React, {Component} from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';

import axios from 'axios';
import 'spark-md5';
import SparkMD5 from 'spark-md5';
var config = require('./config.json');

const maxLength = (len) => (val) => !(val) || (val.length) <= len;
const minLength = (len) => (val) => (val) && (val.length) >= len;

class SetNewPassword extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            password : "",
            confirmPassword : "",

            actualPasswordNotMatch : false,
            invalidPassword: false,
            allValid: false,

            errMessage: "",
            succMessage: "",

            // frontend_apiurl: 'localhost',
            // frontend_port: '3000',
        }
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this)
    }

    handlePasswordChange(e) {
        this.setState({...this.state, 
            [e.currentTarget.id] : e.currentTarget.value
        }, () => 
            {
                this.setState({
                    actualPasswordNotMatch : (this.state.password !== this.state.confirmPassword)
                }, ()=>{this.setState({allValid: this.checkAllValid()})
                });

                this.setState({
                    invalidPassword: !minLength(3)(this.state.password) || !maxLength(20)(this.state.password)
                }, ()=>{this.setState({allValid: this.checkAllValid()})
                });
            }
        )}


    checkAllValid() {
        const allClear = !this.state.invalidPassword && !this.state.actualPasswordNotMatch
        return allClear
    }

    handlePasswordSubmit(e) {
        e.preventDefault();
        let body = {
            password: SparkMD5.hash(this.state.password),
        }

        const token = this.props.match.params.token
        //console.log(token)
        axios.post(`https://${config.apiurl}:${config.port}/reset_password/${token}`, body) // -> https
        .then((response) => {
            if (response.status === 200) {
                this.setState({succMessage: response.data})
                this.setState({errMessage: ''})
            }
        })
        .catch((error) => {
            this.setState({errMessage: error.response.data})
        })
    }


    render()
    {
        let error = ''
        if (this.state.errMessage) {
            error = (
            <Alert variant='danger'>{this.state.errMessage}</Alert>
            )
        }
        let success = ''
        if (this.state.succMessage) {
            success = (
            <Alert variant='success'> {this.state.succMessage} </Alert>
            )
        }

        return (
            <div className='col-12 col-sm-8 offset-sm-2'>
                <h1>Reset Password</h1>
                <Form onSubmit={this.handlePasswordSubmit}>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type = "password" id = "password" required size = "sm" placeholder="New Password"
                        value = {this.state.password} onChange = {e => this.handlePasswordChange(e)}/>
                        {this.state.invalidPassword &&
                            (<Form.Text className="text-danger">
                                password should be between 3 and 20 characters
                            </Form.Text>)
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type = "password" id = "confirmPassword" required size = "sm" placeholder="Re-enter Password"
                        value = {this.state.confirmPassword} onChange = {e => this.handlePasswordChange(e)}/> 
                        {this.state.actualPasswordNotMatch &&
                            (<Form.Text className="text-danger">
                                The passwords currently do not match
                            </Form.Text>)
                        }
                    </Form.Group>

                    <div>
                        {!this.state.allValid &&
                        <Button type="submit" className = "btn btn-primary" disabled>Reset Password</Button> }
                        { this.state.allValid &&
                        <Button type="submit" className = "btn btn-primary" >Reset Password</Button> }
                    </div>
                </Form>
                <br></br>
                {error}
                {success}
                <Link to="/">Back to Login</Link>
            </div>
        )

    }



}

export default SetNewPassword;