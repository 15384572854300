import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import './index.css';
// import './LandingPage.css';

import Tabs from './Tabs';
import LandingPage from './LandingPage';
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import CreateUserPage from './CreateUserPage';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import Cookies from 'js-cookie';
import ResetPassword from './ResetPassword';
import SetNewPassword from './SetNewPassword';

require('./styles.css');

function PrivateRoute({component: Component}, ...rest)
{
    return (
        <Route 
            {...rest}
            render = {props => 
                Cookies.get('Auth') !== undefined ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to = {{
                            pathname : '/',
                            state : { from : props.location }
                        }}
                    />
                )
            }    
        />
    );
}

function App()
{
    return (
        <BrowserRouter>
          <Switch>
            <Route exact path = '/' component={LoginPage}/>
            <PrivateRoute exact path = '/landingpage' component={HomePage}/>
            <Route exact path = '/createuser' component={CreateUserPage}/>
            <Route exact path = '/reset_password' component={ResetPassword}/>
            <Route exact path = '/reset_password/:token' component={SetNewPassword}/>
          </Switch>
        </BrowserRouter>
    );
}

//??? document.getElementById('root')
const container = document.createElement('div');
document.body.appendChild(container);
render(<App/>, container);