import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Row, Col, Form, Spinner, Button, Badge } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { useHistory, Link } from "react-router-dom";
  
import axios from 'axios';
import 'spark-md5';
import SparkMD5 from 'spark-md5';
const { uuid, isUuid } = require('uuidv4');

var config = require('./config.json');

//
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);
const maxLength = (len) => (val) => !(val) || (val.length) <= len;
const minLength = (len) => (val) => (val) && (val.length) >= len;
//console.log(validEmail('asf@gmail.com'))
//console.log(minLength(5)('lalala'))


class CreateUserPage extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            login : {
                username : "",
                password : "",
                confirmPassword : "",
                email : "",
                db_name : "",
                db_guid : uuid()
            },
            passwordNotMatch : false, // not being used so far
            actualPasswordNotMatch : false,

            invalidEmail : false,
            usedEmail: false, // not being used so far
            invalidUsername: false,
            usedUsername: false, // not being used so far
            invalidPassword: false,
            invalidDBName: false,
            invalid_GUID : false,
            
            allValid: false,

            errMessage: "",
            succMessage: "",
            isLoading: false, //
            loadingMess: "Creating New User ...",

        }
        this.handleCreateUserChange = this.handleCreateUserChange.bind(this); //
        this.checkAllValid = this.checkAllValid.bind(this); 
    }

    // def add_appropriate_dashes(eye_dee):
	// lens = [8,4,4,4,12]
	// current_sum = 0
	// new_id = ''
	// for l in lens:
	// 	if len(eye_dee) - current_sum < l:
	// 		new_id += eye_dee[current_sum:]
	// 		break
	// 	for i in range(l):
	// 		new_id += eye_dee[current_sum + i]
	// 	current_sum += l

	// 	if l == 12:
	// 		break
	// 	new_id += '-'
    // return new_id
    

    add_appropriate_dashes(eye_dee)
    {
        console.log(`This is the id : ${eye_dee}, and its length : ${eye_dee.length}`);

        let lens = [8,4,4,4,12];
        let current_sum = 0;
        let new_id = '';

        for(let i = 0; i < lens.length; ++i)
        {
            let l = lens[i];

            if ((eye_dee.length - current_sum < l))
            {
                new_id += eye_dee.substring(current_sum);
                break;
            }
            for(let j = 0; j < l; ++j)
            {
                new_id += eye_dee[current_sum + j];
            }
            current_sum += l;
            
            if (l === 12 || current_sum === eye_dee.length)
            {
                break;
            }
            new_id += '-';
        }



        return eye_dee.length > 32 ? new_id + eye_dee.substring(current_sum) : new_id;
    }


    checkAllValid() {
        //console.log('checkAllValid run')
        const allClear = !this.state.invalidEmail && !this.state.invalidUsername && !this.state.invalidPassword && !this.state.actualPasswordNotMatch && !this.state.invalidDBName && !this.state.invalid_GUID //&& !this.state.usedUsername
        //console.log(allClear)
        return allClear
    }

    handleCreateUserChange(e)
    {
        var id = e.currentTarget.id;
        this.setState({...this.state, login : { //???
            ...this.state.login,
            [e.currentTarget.id] : e.currentTarget.value
        }}, () => 
        {
            if (id === 'db_guid')
            {
                let new_id = this.add_appropriate_dashes(this.state.login.db_guid.replace(/-/gi, ''));
                console.log(`NEW ID : ${new_id}, ${!isUuid(new_id)}`);
                this.setState({...this.state,
                    login : {
                        ...this.state.login,
                        db_guid : new_id
                    },
                    invalid_GUID : !isUuid(new_id)
                }, ()=>{this.setState({allValid: this.checkAllValid()})
                });
            }
            else if(id === 'password' || id === 'confirmPassword')
            {
                this.setState({...this.state,
                    actualPasswordNotMatch : (this.state.login.password !== this.state.login.confirmPassword)
                }, ()=>{this.setState({allValid: this.checkAllValid()})
                });

                this.setState({
                    invalidPassword: !minLength(3)(this.state.login.password) || !maxLength(20)(this.state.login.password)
                }, ()=>{this.setState({allValid: this.checkAllValid()})
                });

            }

            else if(id === 'email')
            {
                this.setState({
                    invalidEmail: !validEmail(this.state.login.email)
                }, ()=>{this.setState({allValid: this.checkAllValid()})
                });
            }

            else if(id === 'username')
            {
                this.setState({
                    invalidUsername: !minLength(3)(this.state.login.username) || !maxLength(15)(this.state.login.username)
                }, ()=>{this.setState({allValid: this.checkAllValid()})
                });
            }

            else if(id === 'db_name')
            {
                this.setState({
                    invalidDBName: !minLength(3)(this.state.login.db_name) || !maxLength(15)(this.state.login.db_name)
                }, ()=>{this.setState({allValid: this.checkAllValid()})
                });
            }
            
        });
    }

    handleCreateUser = (e) => {
        return new Promise(() => {
            e.preventDefault(); //???
            if(this.state.login.password !== this.state.login.confirmPassword)
            {
                this.setState({
                    login : {
                        email: this.state.login.email,
                        username : this.state.login.username,
                        password: "",
                        confirmPassword : "",
                        db_name : this.state.login.db_name.toLowerCase(),
                        db_guid : this.state.login.db_guid.replace('-', '')
                    }, 
                    passwordNotMatch : true
                });
            }
            else
            {
                let body = {
                    email: this.state.login.email,
                    username : this.state.login.username,
                    password : SparkMD5.hash(this.state.login.password),
                    db_name : this.state.login.db_name,
                    db_guid : this.state.login.db_guid
                }
                console.log(config.apiurl);

                this.setState({isLoading: true}, ()=>{
                    axios.post(`https://${config.apiurl}:${config.port}/createuser`, body) // -> https
                    .then((response) => {
                        if (response.status === 201)
                        {   
                            //console.log(response.status + ': ' + response.data);
                            this.setState({isLoading: false})
                            this.setState({succMessage: response.data + ' Congratulations! You can now go back to login'})
                            this.setState({errMessage: ''})
                            //this.setState({invalidUserName : true}, () => {console.log(this.state)});
                        }
                        else
                        {
                            this.props.history.push('/');
                        }
                    })
                    .catch((error) => {
                        //console.log(error.response.data);
                        this.setState({isLoading: false})
                        this.setState({errMessage: error.response.data})
                        this.setState({succMessage: ''})
                    });
                })

            }
            
        });
    }

    handle_GUID_click = () =>
    {
        this.setState({...this.state, invalid_GUID : false, login : {...this.state.login, db_guid : uuid()}},
        ()=>{this.setState({allValid: this.checkAllValid()})
        })
    }

    render()
    {

        let error = ''
        if (this.state.errMessage) {
            error = (
            <Alert variant='danger'>{this.state.errMessage}</Alert>
            )
        }
        let success = ''
        if (this.state.succMessage) {
            success = (
            <Alert variant='success'> {this.state.succMessage} </Alert>
            )
        }
        let loading = ''
        if (this.state.isLoading) {
            loading = (
            <Alert variant='warning'> {this.state.loadingMess} </Alert>
            )
        }

        if(this.state.passwordNotMatch)
        {
            return (
                <Alert variant = "danger" onClose = {() => {this.setState({...this.state, passwordNotMatch : false});}} dismissible>
                    <Alert.Heading>Passwords did not match, please try again.</Alert.Heading>
                </Alert>
            )
        }
        return (
            <div className='col-12 col-sm-8 offset-sm-2'>
                <h1>Create User Page</h1>
                {error}
                {success}
                <Form onSubmit={this.handleCreateUser} >
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type = "text" id = "email" required size = "sm" placeholder="Enter Unique Email"
                                        value = {this.state.login.email} onChange = {e => this.handleCreateUserChange(e)}/>
                        {this.state.invalidEmail &&
                            (<Form.Text className="text-danger">
                                Invalid Email Address
                            </Form.Text>)
                        }
                    </Form.Group>


                    <Form.Group>
                        <Form.Label>User Name</Form.Label>
                        <Form.Control type = "text" id = "username" required size = "sm" placeholder="Enter Unique Username"
                        value={this.state.login.username} onChange={e => this.handleCreateUserChange(e)}/>
                        {this.state.invalidUsername &&
                            (<Form.Text className="text-danger">
                                username should be between 3 and 15 characters
                            </Form.Text>)
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type = "password" id = "password" required size = "sm" placeholder="Password"
                        value = {this.state.login.password} onChange = {e => this.handleCreateUserChange(e)}/>
                        {this.state.invalidPassword &&
                            (<Form.Text className="text-danger">
                                password should be between 3 and 20 characters
                            </Form.Text>)
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type = "password" id = "confirmPassword" required size = "sm" placeholder="Re-enter Password"
                        value = {this.state.login.confirmPassword} onChange = {e => this.handleCreateUserChange(e)}/> 
                        {this.state.actualPasswordNotMatch &&
                            (<Form.Text className="text-danger">
                                The passwords currently do not match
                            </Form.Text>)
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Database Name</Form.Label>
                        <Form.Control type = "text" id = "db_name" required size = "sm" placeholder="Set a Database Name"
                        value = {this.state.login.db_name} onChange = {e => this.handleCreateUserChange(e)}/>
                        {this.state.invalidDBName &&
                            (<Form.Text className="text-danger">
                                database name should be between 3 and 15 characters
                            </Form.Text>)
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Database GUID</Form.Label>
                        <Form.Control type = "text" id = "db_guid" required size = "sm" placeholder="Click Generate New GUID to Create"
                        value = {this.state.login.db_guid} onChange = {e => this.handleCreateUserChange(e)}/> 
                        {this.state.invalid_GUID &&
                            (<Form.Text className="text-danger">
                                Invalide GUID, click Generate New GUID below to generate one
                            </Form.Text>)
                        }
                    </Form.Group>
                    <Form.Text className="text-info badge">
                        NOTE: If you are trying to join an existing database, make sure to have the Database Name AND the GUID match.
                    </Form.Text>
                    <div>
                        <Button type="" variant="info" onClick = { this.handle_GUID_click }>Generate New GUID</Button>
                    </div>
                    <br></br>
                    <div>
                        { !this.state.allValid &&
                        <Button type="submit" className = "btn btn-primary" disabled>Create User</Button> }
                        { this.state.allValid &&
                        <Button type="submit" className = "btn btn-primary" >Create User</Button> }
                    </div>
                </Form>
                <br></br>
                {loading}
                {error}
                {success}
                <Link to="/">Login Instead</Link>
            </div>
        );
    }
}

export default CreateUserPage;