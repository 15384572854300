
import React, {Component} from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';

import axios from 'axios';

var config = require('./config.json');

const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class ResetPassword extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            invalidEmail: false,
            registeredEmail: false, //not being used yet
            errMessage: "",
            succMessage: "",
            isLoading: false, //
            loadingMess: "Sending Email ...",
        }
        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
    }

    handleEmailChange(e) {
        this.setState( {email: e.currentTarget.value}, () => {this.setState({invalidEmail: !validEmail(this.state.email)})} )
    }


    handleEmailSubmit(e) {
        e.preventDefault();
        let body = {
            email: this.state.email
        }

        this.setState({isLoading: true}, ()=>{
            axios.post(`https://${config.apiurl}:${config.port}/reset_password`, body) // -> https
            .then((response) => {
                if (response.status === 200) {
                    this.setState({isLoading: false})
                    this.setState({succMessage: response.data})
                    this.setState({errMessage: ''})
                }
            })
            .catch((error) => {
                this.setState({isLoading: false})
                this.setState({errMessage: error.response.data})
            })
        })
    }


    render()
    {
        let error = ''
        if (this.state.errMessage) {
            error = (
            <Alert variant='danger'>{this.state.errMessage}</Alert>
            )
        }
        let success = ''
        if (this.state.succMessage) {
            success = (
            <Alert variant='success'> {this.state.succMessage} </Alert>
            )
        }

        let loading = ''
        if (this.state.isLoading) {
            loading = (
            <Alert variant='warning'> {this.state.loadingMess} </Alert>
            )
        }

        return (
            <div className='col-12 col-sm-8 offset-sm-2'>
                <h1>Reset Password</h1>
                <Form onSubmit={this.handleEmailSubmit}>
                    <Form.Group>
                        <Form.Label>Enter the email address used to create your account</Form.Label>
                        <Form.Control type = "text" id = "email" required size = "sm" placeholder="Email Address"
                                        value = {this.state.email} onChange = {e => this.handleEmailChange(e)}/>
                        {this.state.invalidEmail &&
                            (<Form.Text className="text-danger">
                                Invalid Email Address
                            </Form.Text>)
                        }
                    </Form.Group>

                    <div>
                        {this.state.invalidEmail &&
                        <Button type="submit" className = "btn btn-primary" disabled>Send Email</Button> }
                        { !this.state.invalidEmail &&
                        <Button type="submit" className = "btn btn-primary" >Send Email</Button> }
                    </div>
                </Form>
                <br></br>
                {loading}
                {error}
                {success}
                <Link to="/">Back to Login</Link>
            </div>
        )

    }


}

export default ResetPassword;