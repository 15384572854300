import React, {Component} from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import Cookies from 'js-cookie';
var config = require('./config.json');

class FileUpload extends Component{
    constructor(props){
        super(props);
        this.state={
            fileType: props.fileType,
            count: 1,
            titles: props.titles,
            valid_fields: ["userID", "event_type", "event_name", "start_time", "end_time", "parameters", "datastreams"],
            files: [],
            xml: {name:"", file:""}
        };
        this.onChange = this.onChange.bind(this);
    }
    hideError = (e) =>{
        let childNode = document.getElementById(e.target.parentNode.id).parentNode;
        document.getElementById(e.target.parentNode.id).parentNode.parentNode.removeChild(childNode);
    }
    showError = (message, id) =>{
        if(document.getElementById("error"+id) !== null) return;
        let html = "<div class='alert alert-danger alert-dismissible fade show' role='alert'>"+
        message+"<button id='error"+id+"' type='button' class='close' aria-label='Close'>"+
        "<span aria-hidden='true'>&times;</span></button></div>";
        document.getElementById(id).insertAdjacentHTML('afterend',html);
        document.getElementById("error"+id).addEventListener("click", this.hideError);
    }
    handleSubmit = (e) =>{
        if (this.state.fileType === ".xml"){
            if (this.state.xml.name === "" || this.state.xml.file === ""){
                this.showError("Please submit an XML file along with the participant's name!", e.target.id);
                return;
            }
            //refresh window
            return;
        }

        if (this.state.files.length === 0) {
            this.showError("You have not submitted any files!", e.target.id);
            return; 
        }
        
        for (var i = 0; i < this.state.files.length; i++){
            let table = this.state.files[i].title;
            let headers = {
                        Auth : Cookies.get('Auth'),
                        Username : Cookies.get('Username')
                    };
            Papa.parse(this.state.files[i].file, {
                header:true,
                complete: results =>{
                    axios.post(`https://${config.apiurl}:${config.port}/data-upload`, {tableName:table,csvData:results}, {headers})
                    .then((response) => {
                        console.log(response); //refresh window
                        })
                        .catch((error) => {
                            console.error(`Encountered this error while trying to upload csv file ${error.response}`);
                        })
                }
            });
            
        }
        
    }
    handleTitleInput = (e) =>{
        var files = this.state.files;
        if (e.target.value.length === 0){
            for (var file in this.state.files){
                if (e.target.parentNode.parentNode.id === this.state.files[file].id){
                    var menu = document.getElementById("option"+e.target.parentNode.parentNode.id).options;
                    var titleName = menu[menu.selectedIndex].value;
                    files[file].title = titleName;
                    this.setState({files});
                    return;
                }
            }
        }
        else{
            for (var file in this.state.files){
                if (e.target.parentNode.parentNode.id === this.state.files[file].id){
                    files[file].title = e.target.value;
                    this.setState({files});
                    return;
                }
            }
        }
    }
    handleOption = (e) =>{
        if (document.getElementById("text"+e.target.parentNode.parentNode.id).value.length === 0){
            var files = this.state.files;
            for (var file in this.state.files){
                if (e.target.parentNode.parentNode.id === this.state.files[file].id){
                    var menu = document.getElementById(e.target.id).options;
                    var titleName = menu[menu.selectedIndex].value;
                    files[file].title = titleName;
                    this.setState({files});
                    return;
                }
            }
        }        

    }
    onChange = (e) =>{
        if (e.target.files === undefined || e === undefined || e.target === undefined) return; 
        var targetFile = e.target;
        var submittedFile = e.target.files[0];
        var files = this.state.files;
        var ID = e.target.parentNode.id;
        var fields = this.state.valid_fields;
        var errorFunction = this.showError;
        try {
            Papa.parse(e.target.files[0], {
            header: true,
            complete: function(results) {
                document.getElementById("fileSubmit").disabled=false;
                document.getElementById("addFile").disabled=false;
                var newFiles = files;
                var badFile = false;

                for(var field in results.meta.fields){
                    if (!(field in fields)){
                        targetFile.value = null;
                        for (var file in newFiles){
                            if (newFiles[file].id === ID){
                                files.splice(file,1);
                                badFile = true;
                                break;
                            }
                        }
                        errorFunction("File has been removed. Please submit a file with the following structure: userID, event_type, event_name, start_time, end_time, parameters, datastreams", ID);
                        break;
                    }
                }
                if (!badFile){
                    for (var file in newFiles){
                        if (newFiles[file].id === ID) {
                            files.splice(file,1);
                            break;
                        }
                    }
                    var textInput = document.getElementById("text"+ID).value;
                    var menu = document.getElementById("option"+ID).options;
                    var option = menu[menu.selectedIndex].value;
                    var titleName = "";
                    if (textInput.length === 0) titleName = option;
                    files.push({id: ID, title: titleName, file:submittedFile}); 
                }
            }
        });
        this.setState({files});
    }catch(err){console.error(err);}
        
    }
    addFile = (e) =>{
        var options = "";
        for (var option in this.state.titles){
            options+="<option value="+this.state.titles[option]+" key="+this.state.titles[option]+">"+this.state.titles[option]+"</option>";
        }
        var cnt = this.state.count;
        this.setState({count: cnt+1});
        
        var html = "<div class='border-bottom section' id="+cnt+"><input class='file-upload' type='file' accept='.csv' name='file' id='file"+cnt+"'></input>"+
        "<label id='"+cnt+"'>Choose a table title:<select class='dropdown-menu-titles form-control' id='option"+cnt+"'>"+
        options+"</select></label><div><strong>or</strong></div>"+
        "<label>Type new table name: <input type='text' class='form-control' aria-label='Type new table name' id='text"+cnt+"'></input>"+
        "</label></div>";
        cnt--;
        document.getElementById(cnt.toString()).insertAdjacentHTML('afterend', html);
        document.getElementById("file"+(cnt+1)).onchange = this.onChange;
        document.getElementById("option"+(cnt+1)).onchange = this.handleOption;
        document.getElementById("text"+(cnt+1)).onchange = this.handleTitleInput;
    }
    handleXMLFile = (e) =>{
        var file = e.target.files[0];
        var xmlDict = this.state.xml;
        xmlDict.file = file;
        this.setState(xmlDict);
    }
    handleParticipantName = (e) =>{
        let name = e.target.value;
        let xmlDict = this.state.xml;
        if (name !== "") xmlDict.name = name;
        this.setState(xmlDict);
    }
    render(){
        let options = this.state.titles.map(t => ( <option value={t} key={t}>{t}</option> ));
        if (this.state.fileType === ".csv") 
        {
            

            return (
                    <div className="file-submit-section shadow-lg">
                        <h5>Upload your CSV file(s):</h5>
                        <div className="border-bottom section" id="0">
                            <input className="file-upload" type="file" accept=".csv" name="file" id="file0" onChange={(e)=>this.onChange(e)}></input>
                            <label>Choose a table:<select className="dropdown-menu-titles form-control" id="option0" onChange={(e)=>this.handleOption(e)}>
                                {options}</select>
                            </label>
                            <div><strong>or</strong></div>
                            <label>Type new table name: <input type="text" className="form-control" aria-label="Type new table name" id="text0" onBlur={(e)=>this.handleTitleInput(e)}></input>
                            </label>
                        </div>
                        <button type="button" className="btn btn-light" id="addFile" onClick={this.addFile}>+Add file</button>
                        <button type="submit" className="btn btn-primary" id="fileSubmit" onClick={this.handleSubmit}> Upload File(s) </button>
                    </div>
            )
        }
        else {
            return (
                    <div className="file-submit-section shadow-lg">
                        <h5>Upload your XML file:</h5>
                        <div className="border-bottom section">
                            <input className="file-upload" type="file" accept=".zip,.rar,.7zip" name="file" onChange={(e)=>this.handleXMLFile(e)} required={true}></input>
                            <label>Participant name: <input type="text" className="form-control" aria-label="Type participant name" id="participant-name" onBlur={(e)=>this.handleParticipantName(e)} required={true}></input>
                            </label>
                        </div>
                        <button type="submit" className="btn btn-primary" id="fileSubmitXML" onClick={this.handleSubmit}> Upload File </button>
                    </div>
            )
        }
    }
}

export default FileUpload;