import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Row, Col, Form, Spinner, Button } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { useHistory, Link } from "react-router-dom";
  
import axios from 'axios';
import 'spark-md5';
import SparkMD5 from 'spark-md5';
import Cookies from 'js-cookie';
//Adding style sheet
import './styles.css';

var config = require('./config.json');

class LoginPage extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            login : {
                username : "",
                password : ""
            },
            failedLogin : false     
        }
    }

    handleLoginChange(e)
    {
        this.setState({
            ...this.state, 
            login : {
                ...this.state.login,
                [e.currentTarget.id] : e.currentTarget.value
            }
        })
    }

//???
    handleLogIn = async (e) =>
    {
        return new Promise(async () => {
            e.preventDefault();
            
            let body = {
                username : this.state.login.username,
                password : SparkMD5.hash(this.state.login.password)
            }
            console.log(this.state);

            axios.post(`https://${config.apiurl}:${config.port}/login`, body) // -> https
            .then((response) => {
                // console.log(response.data.auth_token);
                // console.log('AUTH TOKEN!');
                var in10Minutes = 1 / 144;
                Cookies.set('Auth', response.data.auth_token, {expires : in10Minutes});
                Cookies.set('Username', this.state.login.username, {expires : in10Minutes});
                console.log('Finished setting mah cookies :)');
                this.props.history.push('/landingpage');
            }).catch((error) => {
                console.log(error);
                this.setState({...this.state, failedLogin : true});
            });
        }); 
    }

    render()
    {
        let error = ''
        if (this.state.failedLogin) {
            error = (
            <Alert variant='danger'>Invalid username or password</Alert>
            )
        }
        
        return (
            <div className="loginContainer bg-primary shadow-lg">
                <h1>Login Page</h1>
                <Form onSubmit={this.handleLogIn}>

                <Form.Group>
                    <Form.Label>User Name</Form.Label>
                    <Form.Control type="text" id="username" required size="sm"
                    value={this.state.login.username} onChange={e => this.handleLoginChange(e)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Link to="/reset_password" className="col-5 float-right">Forgot Password?</Link>
                    <Form.Control type = "password" id = "password" required size="sm"
                    value = {this.state.login.password} onChange = {e => this.handleLoginChange(e)}/> 
                </Form.Group>
                <br></br>
                <Button type="submit" className = "btn btn-light">Log In</Button>
                </Form>
                <br></br>
                {error}
                <Link to="/createuser">Create an Account Instead</Link>
            </div>
        );
    }
}

export default LoginPage;