import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import axios from 'axios';  
import {Table} from 'react-bootstrap';

import './index.css';

var moment = require('moment-timezone');
var config = require('./config.json');

function verifyCookies()
{
    if (Cookies.get('Auth') === undefined || Cookies.get('Username') === undefined)
    {
        console.error('Not logged in!');
        window.location.reload();
    }
    else
    {
        var in10Minutes = 1 / 144;
        Cookies.set('Auth', Cookies.get('Auth'), {expires : in10Minutes});
        Cookies.set('Username', Cookies.get('Username'), {expires : in10Minutes});
    }
}


class EventOperations extends Component
{
    //This makes sure that the Events component is constructed with the new database
    //and its user to make web requests with
    static propTypes = {
        new_database : PropTypes.object.isRequired,
        user : PropTypes.string.isRequired,
        onRefreshEvents : PropTypes.func.isRequired,
        datastreams: PropTypes.object.isRequired,
        dataTransformations: PropTypes.object.isRequired,
        eventOperations: PropTypes.object.isRequired,
        patternOperations: PropTypes.object.isRequired,
        patternSelectedEvents: PropTypes.object.isRequired
    };

    constructor(props)
    {
        super(props);
        console.log(this.props);
        let eventOptions = this.loadEventOptions();
        this.state = {
            ceOptions: eventOptions,//replace filler data with properties
            // new_database: this.props.new_database,
            datastreamOptions: this.props.datastreams,
            complexEventsList: this.props.eventOperations,
            dataTransformationList: this.props.dataTransformations,
            currentDataTransformationName: null,
            currentDataStream: (this.props.datastreams.length > 0)? this.props.datastreams[0]: null,
            currentDataTransformation: {
                inputEvent0 :[null, null, null]
            },

            currentEventOperationResultName: null,
            currentEventTransformation: {
                event1 : (eventOptions.length > 0)? eventOptions[0]: null,
                event2 : (eventOptions.length > 0)? eventOptions[0]: null,
                operation: "OR"
            },
            patternOptions : this.loadPatternOptions(),
            patternOperations: this.props.patternOperations,
            patternSelectedEvents: this.props.patternSelectedEvents,
            currentPatternSelectionName: null,
            currentPatternSelectionOperation: {
                patternName: null,
                eventName: null
            }
        };

        this.handleDataStreamSelection = this.handleDataStreamSelection.bind(this);
        this.handleEventDropdownChange1 = this.handleEventDropdownChange1.bind(this);
        this.handleEventDropdownChange2 = this.handleEventDropdownChange2.bind(this);
        this.handleEventOperatorDropdownChange = this.handleEventOperatorDropdownChange.bind(this);
        this.addComplexEvent = this.addComplexEvent.bind(this);
        this.handlePatternDropdownChange = this.handlePatternDropdownChange.bind(this);
        this.handlePatternEventSelectionDropdownChange = this.handlePatternEventSelectionDropdownChange.bind(this);
        // this.handlePatternSelectionNameChange = this.handlePatternSelectionNameChange(this);
    }
    
    loadDatastreamOptions(){
        let optionsList = new Set();
        console.log(this.state.data);
        this.props.new_database.forEach((doc) => {
            optionsList.add(doc.type);
        });
        return [...optionsList]
    }

    loadEventOptions(){
        let optionsList = new Set();
        console.log(this.props.new_database);
        this.props.new_database.forEach((doc) => {
            optionsList.add(doc.type);
        });
        return [...optionsList]
    }

    loadPatternOptions(){
        let optionsList = new Set();
        console.log(this.props.patternOperations);

        this.props.patternOperations.forEach((doc) => {
            optionsList.add(doc.title);
        });
        return [...optionsList];
    }

    handleAddRange = (e) =>{
        //add movement options to dataTransformationList
        //display in list
        console.log("Adding an event range");
        let ids = Object.keys(this.state.currentDataTransformation);
        let numEvents = ids.length;

        let currentRanges = JSON.parse(JSON.stringify(this.state.currentDataTransformation));
        let new_id = "inputEvent"+numEvents;
        currentRanges[new_id] = [null, null, null]

        this.setState({
            currentDataTransformation : currentRanges
        });

    }

    addComplexEvent(e){
        verifyCookies();
        //add new event to ceOptions list to add to event dropdown menus
        let newEventOperation = this.state.currentEventTransformation;
        let newEventStreamName = this.state.currentEventOperationResultName;

        if(!newEventStreamName){
            alert('Eventstream Name cannot be empty!');
            return;
        }

        let eventRequest = {
            "event-stream1-name" : newEventOperation.event1,
            "event-stream2-name" : newEventOperation.event2,
            "operator" : newEventOperation.operation,
            "result-name" : newEventStreamName
        };

        //call api
        let header_config = {
            headers : {
                Auth : Cookies.get('Auth'),
                Username : Cookies.get('Username'),
                user : this.props.user
            }
        }
        

        axios.post(`https://${config.apiurl}:${config.port}/event-combination`, eventRequest, header_config)
        .then((created_eventstream_details) => {
            let eventOperationList = this.state.complexEventsList.slice();
            eventOperationList.push(eventRequest);

            this.setState({
                complexEventsList : eventOperationList
            });
            this.props.eventOperations.push(eventRequest);

            let returnedEvents = created_eventstream_details.data[newEventStreamName];

            console.log(returnedEvents)
            let tz = "";

            returnedEvents.forEach((event_datum) => {
                // console.log("Champak");
                let x = event_datum.start_time.split(' ');
                // console.log(x);
                let start_date = moment(`${x[0]} ${x[1]} ${x[2]}`, 'YYYY-MM-DD HH:mm:ss Z', true).utcOffset(x[2]);

                x = event_datum.end_time.split(' ');
                let end_date = moment(`${x[0]} ${x[1]} ${x[2]}`, 'YYYY-MM-DD HH:mm:ss Z', true).utcOffset(x[2]);
                tz = x[2];

                console.log(`EventStreamOperation: start time string : ${event_datum.start_time}\nand the end time string : ${event_datum.end_time}`);
                console.log(`EventStreamOperation: start date: ${start_date}\nand my end date: ${end_date}`);

                //Build and add the data recieved to our database
                let data_body = {
                    name : event_datum.event_name,
                    type : event_datum.event_type,
                    start_date : start_date,
                    end_date : end_date,
                };

                this.props.new_database.add(data_body);
                // this.props.onRefreshEvents();

                // this.setState({
                //     ceOptions: this.loadEventOptions()
                // });

                //Set the user if we dont have one yet. 
                // if(user === '')
                // {
                //     user = event_datum.userID;
                // }
            });
            let eventOptions = this.loadEventOptions();
            this.setState({
                ceOptions: eventOptions,
                currentEventOperationResultName: null,
                currentEventTransformation: {
                    event1 : (eventOptions.length > 0)? eventOptions[0]: null,
                    event2 : (eventOptions.length > 0)? eventOptions[0]: null,
                    operation: "OR"
                },
            });
            this.props.onRefreshEvents();
        })
        .catch((error) => {
            //DEAL WITH AN ERROR IN HERE.

        })

        //display in list
    }

    handleEventOperationResultNameChange(e){
        this.setState({
            currentEventOperationResultName: e.currentTarget.value
        });
    }

    handleEventDropdownChange1(e){
        verifyCookies();
        let currentOperation = JSON.parse(JSON.stringify(this.state.currentEventTransformation));
        currentOperation.event1 = e.target.value;
        this.setState({
            currentEventTransformation: currentOperation
        });
    }

    handleEventDropdownChange2(e){
        verifyCookies();
        let currentOperation = JSON.parse(JSON.stringify(this.state.currentEventTransformation));
        currentOperation.event2 = e.target.value;
        this.setState({
            currentEventTransformation: currentOperation
        });
    }

    handleEventOperatorDropdownChange(e){
        verifyCookies();
        let currentOperation = JSON.parse(JSON.stringify(this.state.currentEventTransformation));
        currentOperation.operation = e.target.value;
        this.setState({
            currentEventTransformation: currentOperation
        });
    }


    handleSubmitDataTransformation = (e) =>{

        verifyCookies();
        // create JSON request and call data transformation API 
        let currentTransformation = JSON.parse(JSON.stringify(this.state.currentDataTransformation));
        if(!this.state.currentDataTransformationName){
            alert('Event stream name cannot be empty!')
        }
        let eventstreamName = this.state.currentDataTransformationName;
        let ids = Object.keys(currentTransformation);
        let transformationJSON = {};

        for(let i=0; i<ids.length; i++){
            var curName = currentTransformation[ids[i]][0];
            
            var curRange = currentTransformation[ids[i]].slice(1);
            if(Object.keys(transformationJSON).includes(curName)){
                alert('Events must have distinct names!');
            }
            transformationJSON[curName] = curRange;
        }
        let requestJSON = {
            "event-stream-name": this.state.currentDataTransformationName,
            "data-stream-name": this.state.currentDataStream,
            "transformation-object": transformationJSON
        }

        console.log("Sending transformation");

        //call api
        let header_config = {
            headers : {
                Auth : Cookies.get('Auth'),
                Username : Cookies.get('Username'),
                user : this.props.user
            }
        }
        

        axios.post(`https://${config.apiurl}:${config.port}/data-transformation`, requestJSON, header_config)
        .then((created_eventstream_details) => {

            // console.log(created_eventstream_details);
            let transformationList = this.state.dataTransformationList.slice();
            transformationList.push(requestJSON);
            this.setState({
                dataTransformationList: transformationList
            },()=>{console.log(JSON.stringify(this.state, undefined, 4));
                    this.props.dataTransformations.push(requestJSON)});
            // add event_type, event_name, start_date, end_date
            // refer HomePage.js lines 80-87
            let returnedEvents = created_eventstream_details.data[eventstreamName].slice();
            console.log(returnedEvents)
            let tz = "";

            returnedEvents.forEach((event_datum) => {
                // console.log("Champak");
                let x = event_datum.start_time.split(' ');
                // console.log(x);
                let start_date = moment(`${x[0]} ${x[1]} ${x[2]}`, 'YYYY-MM-DD HH:mm:ss Z', true).utcOffset(x[2]);

                x = event_datum.end_time.split(' ');
                let end_date = moment(`${x[0]} ${x[1]} ${x[2]}`, 'YYYY-MM-DD HH:mm:ss Z', true).utcOffset(x[2]);
                tz = x[2];

                // console.log(`DataStreamTransformation: start time string : ${event_datum.start_time}\nand the end time string : ${event_datum.end_time}`);
                // console.log(`DataStreamTransformation: start date: ${start_date}\nand my end date: ${end_date}`);

                //Build and add the data recieved to our database
                let data_body = {
                    name : event_datum.event_name,
                    type : event_datum.event_type,
                    start_date : start_date,
                    end_date : end_date,
                };

                this.props.new_database.add(data_body);
                // this.props.onRefreshEvents();

                // this.setState({
                //     ceOptions: this.loadEventOptions()
                // });

                //Set the user if we dont have one yet. 
                // if(user === '')
                // {
                //     user = event_datum.userID;
                // }
            });
            this.setState({
                ceOptions: this.loadEventOptions(),
                currentDataTransformationName: null,
                currentDataStream: "steps",
                currentDataTransformation: {
                    inputEvent0 :[null, null, null]
            },
            });
            this.props.onRefreshEvents();
            // this.props.new_database.add();
            // propagates the latest event to all tabs
            // this.props.onRefreshEvents();
            //ALL CODE THAT SHOULD BE DONE AFTER THE WEB CALL SHOULD BE PUT HERE
            //AFTER GETTING THE RESULT YOU CAN CREATE A DATUM TO ADD TO THE HEATMAP 
            //DATA LIST

            //THE LOGIC BELOW SHOULD GIVE YOU A GOOD ROADMAP FOR DEALING WITH THE PATTERN
            //ADDITION LOGIC

            //THE SET STATE COMMAND SHOULD BE IN HERE AS WELL. 

        })
        .catch((error) => {
            //DEAL WITH AN ERROR IN HERE.

        })/**/
        

        // add created event to shared data store

        //add transformation to table
    }

    addPatternEventSelection(e){
        verifyCookies();
        // Create event seletion request
        let selectionRequest = {
            "result-name" : this.state.currentPatternSelectionName,
            "patternName" : this.state.currentPatternSelectionOperation.patternName,
            "eventName" : this.state.currentPatternSelectionOperation.eventName,
        };
        
        let header_config = {
            headers : {
                Auth : Cookies.get('Auth'),
                Username : Cookies.get('Username'),
                user : this.props.user
            }
        }
        
        // send request
        axios.post(`https://${config.apiurl}:${config.port}/patterns/select`, selectionRequest, header_config)
         // if successful then add the selection operation to frontend state
        .then((created_eventstream_details) => {
            let newEventStreamName = this.state.currentPatternSelectionName;
            let eventsData = created_eventstream_details.data[newEventStreamName];
            let tz = "";
            // if successful then add the selection operation to frontend state
            eventsData.forEach((event_datum) => {
                let x = event_datum.start_time.split(' ');
                // console.log(x);
                let start_date = moment(`${x[0]} ${x[1]} ${x[2]}`, 'YYYY-MM-DD HH:mm:ss Z', true).utcOffset(x[2]);

                x = event_datum.end_time.split(' ');
                let end_date = moment(`${x[0]} ${x[1]} ${x[2]}`, 'YYYY-MM-DD HH:mm:ss Z', true).utcOffset(x[2]);
                tz = x[2];

                console.log(`PatternSelectionOperation: start time string : ${event_datum.start_time}\nand the end time string : ${event_datum.end_time}`);
                console.log(`PatternSelectionOperation: start date: ${start_date}\nand my end date: ${end_date}`);

                //Build and add the data recieved to our database
                let data_body = {
                    name : event_datum.event_name,
                    type : event_datum.event_type,
                    start_date : start_date,
                    end_date : end_date,
                };

                this.props.new_database.add(data_body);
            });
            let patternSelections = JSON.parse(JSON.stringify(this.state.patternSelectedEvents));
            patternSelections.push(selectionRequest);

            // send created event metadata to home page state
            this.props.patternSelectedEvents.push(selectionRequest);

            this.setState({
                patternSelectedEvents: patternSelections,
                currentPatternSelectionName: null,
                currentPatternSelectionOperation: {
                    patternName: null,
                    eventName: null
                }
            });
            // refresh the events list in all tabs
            this.props.onRefreshEvents();
        })
        .catch((error) => {
            alert("Error selecting events from pattern!");
        });
       
    }

    handlePatternSelectionNameChange(e){
        verifyCookies();
        // if(e.currentTarget){
            console.log("Changing event selection name")
            this.setState({
                currentPatternSelectionName: e.currentTarget.value
            });
        // }
    }

    handlePatternDropdownChange(e){
        verifyCookies();
        let currentOperation = JSON.parse(JSON.stringify(this.state.currentPatternSelectionOperation));
        currentOperation.patternName = e.target.value;
        this.setState({
            currentPatternSelectionOperation: currentOperation
        });
    }

    handlePatternEventSelectionDropdownChange(e){
        verifyCookies();
        console.log(this.state.currentPatternSelectionOperation)
        if(this.state.currentPatternSelectionOperation.patternName){
            console.log(e.target.value);
            let currentOperation = JSON.parse(JSON.stringify(this.state.currentPatternSelectionOperation));
            currentOperation.eventName = e.target.value;
            this.setState({
                currentPatternSelectionOperation: currentOperation
            });
        }
    }

    handleDataStreamSelection(e){
        verifyCookies();
        this.setState({
            currentDataStream: e.target.value
        });
    }

    handleEventNameChange(e){
        let currentRanges = JSON.parse(JSON.stringify(this.state.currentDataTransformation));
        let id = e.currentTarget.id.slice(0, -4);
        currentRanges[id][0] = e.currentTarget.value;
        this.setState({
            currentDataTransformation : currentRanges
        });
    }

    handleEventStreamNameChange(e){
        this.setState({
            currentDataTransformationName: e.currentTarget.value
        });
    }
    
    handleEventRangeChange(e){
        let range_value = e.currentTarget.value;
        let id = e.currentTarget.id.slice(0, -5);
        if (!range_value.includes(":")){
            return;
        }

        let range_ends = range_value.split(":")
        if ((range_ends.length < 2) || isNaN(range_ends[0]) || isNaN(range_ends[1])){
            return;
        }

        let currentRanges = JSON.parse(JSON.stringify(this.state.currentDataTransformation));
        currentRanges[id][1] = Number(range_ends[0]);
        currentRanges[id][2] = Number(range_ends[1]);
        // console.log(currentRanges)
        this.setState({
            currentDataTransformation : currentRanges
        });
    }

    renderEventNameRow(id){
                                        
        let event_name = this.state.currentDataTransformation[id][0];
        return(
            <input type="text" className="form-control range-input" id={id+"Name"} placeholder="Enter event name" value={event_name} 
                onChange={(e) => {this.handleEventNameChange(e)}}></input>
        )
    }
    renderEventNameList(){
        let ids = Object.keys(this.state.currentDataTransformation);
        // console.log(ids);
        return(
        <div className="event-names" id="event-names">
            {ids.map((id) => {
                
                return( this.renderEventNameRow(id))
            })}
        </div>
        )
    }

    renderEventRangeRow(id){
        let event_range = [this.state.currentDataTransformation[id][1], this.state.currentDataTransformation[id][2]]
        // console.log(id);
        return(
            <input type="text" className="form-control range-input" id={id+"Range"} placeholder="Enter range (value1:value2)" value={(event_range[0] && event_range[1])?event_range[0] + ":" + event_range[1]: null}
                onChange = {(e) => {this.handleEventRangeChange(e)}}></input>  
        )
    }
    renderEventRangeList(){
        let i=0;
        let ids = Object.keys(this.state.currentDataTransformation);
        return <div className="event-ranges" id="event-ranges">
            {ids.map((id) => {
                let tmp=i;
                i += 1;
                return( this.renderEventRangeRow(id))
            })}
        </div>
    }

    renderTransformationList(){
        let transformations = this.state.dataTransformationList;
        let transformationNum = 0;

        return(<Table striped bordered hover size = 'sm' variant = 'dark'>
            <thead>
                <tr>
                <th>Number</th>
                <th>Eventstream</th>
                <th>Source Datastream</th>
                <th>EventName</th>
                <th>Range</th>
                </tr>
            </thead>
            <tbody>
            {transformations.map((transformation) => {
                transformationNum += 1;
                // console.log(JSON.stringify(transformation, undefined, 4));
                let ranges = transformation["transformation-object"];
                let event_names = Object.keys(ranges);
                // console.log(ranges);
                // console.log(event_names);

                let eventStreamName = transformation["event-stream-name"];
                let dataStreamName = transformation["data-stream-name"];

                let i=0;
                return(event_names.map((event_name) => {
                    if(i === 0){
                        // console.log("i is 0, first row of transformation");
                        i+=1;
                        return (
                            <tr >
                                <td>{transformationNum}</td>
                                <td>{eventStreamName}</td>
                                <td>{dataStreamName}</td>
                                <td>{event_name}</td>
                                <td>{ranges[event_name][0] +":"+ ranges[event_name][1]}</td>
                            </tr>
                        );
                    }
                    else{
                        // console.log(`i is ${i}, event name is ${event_name}`);
                        return (
                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{event_name}</td>
                                <td>{ranges[event_name][0] +":"+ ranges[event_name][1]}</td>
                            </tr>
                        );
                    }
                })
                );
            })}
            </tbody>
        </Table>);
    }

    renderEventOperationList(){
        let eventOperations = this.state.complexEventsList;
        let transformationNum = 0;

        return(<Table striped bordered hover size = 'sm' variant = 'dark'>
            <thead>
                <tr>
                <th>Number</th>
                <th>Eventstream Name</th>
                <th>Input1</th>
                <th>Operator</th>
                <th>Input2</th>
                </tr>
            </thead>
            <tbody>
            {eventOperations.map((transformation) => {
                transformationNum += 1;
                // console.log(JSON.stringify(transformation, undefined, 4));
                let esName = transformation["result-name"];
                let event1_name = transformation["event-stream1-name"];
                let event2_name = transformation["event-stream2-name"];
                // console.log(ranges);
                // console.log(event_names);

                let operatorName = transformation["operator"];
                    
                // console.log("i is 0, first row of transformation");
                return (
                    <tr >
                        <td>{transformationNum}</td>
                        <td>{esName}</td>
                        <td>{event1_name}</td>
                        <td>{operatorName}</td>
                        <td>{event2_name}</td>
                    </tr>
                );
                }
                ) 
            }
            </tbody>
        </Table>);
    }

    renderEventSelectionList(){
        let eventSelections = this.state.patternSelectedEvents;
        let selectionNum = 0;

        return(<Table striped bordered hover size = 'sm' variant = 'dark'>
            <thead>
                <tr>
                <th>Number</th>
                <th>Eventstream Name</th>
                <th>Pattern Name</th>
                <th>Selected Event</th>
                </tr>
            </thead>
            <tbody>
            {eventSelections.map((selection) => {
                selectionNum += 1;
                // console.log(JSON.stringify(transformation, undefined, 4));
                let esName = selection["result-name"];
                let patternName = selection["patternName"];
                let selectedEvent = selection["eventName"];
                // console.log(ranges);
                // console.log(event_names);

                // let operatorName = transformation["operator"];
                    
                // console.log("i is 0, first row of transformation");
                return (
                    <tr >
                        <td>{selectionNum}</td>
                        <td>{esName}</td>
                        <td>{patternName}</td>
                        <td>{selectedEvent}</td>
                    </tr>
                );
                }
                ) 
            }
            </tbody>
        </Table>);
    }

    renderPatternEventOptions(){
        if(this.state.currentPatternSelectionOperation.patternName){
            let patternName = this.state.currentPatternSelectionOperation.patternName;
            let pattern = null;
            for(let i=0; i< this.state.patternOperations.length; i++){
                if(this.state.patternOperations[i].title === patternName){
                    pattern = this.state.patternOperations[i];
                    break;
                }
            }
            let eventList = [pattern.event1, pattern.event2];
            return(eventList.map(e => ( <option value={e} key={e} >{e}</option> )));
        }
        else{
            return null;
        }
    }
    render(){
        console.log("Rendering event ops page");
        console.log(this.props);
        console.log(JSON.stringify(this.state, undefined, 4));
        let event_options = this.state.ceOptions.map(t => ( <option value={t} key={t} >{t}</option> ));
        let datastream_options = this.state.datastreamOptions.map(t => ( <option value={t} key={t} >{t}</option> ));
        let pattern_options = this.state.patternOptions.map(p => (<option value={p} key={p} >{p}</option>));
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col event-transformation-section shadow-lg">
                        <h3>Data Stream Transformations</h3>
                        <div id="event-transformation-list">
                            {/* Event Transformation list goes here.. */}
                            {this.renderTransformationList()}
                        </div>
                        <form className="transformation-submit-section">
                            <div className="transformation-section border-top spacer">
                                <div className="row">
                                    <label className="col" htmlFor="transformation-title"><h5>Event stream Title</h5>
                                        <input className="form-control" id="transformation-title" type="text" placeholder="Enter event stream title..."
                                            onChange={(e) => {this.handleEventStreamNameChange(e)}}></input>
                                    </label>
                                    <label className="col"><h5>Data streams</h5>
                                        <select className="form-control" id="datastream-options" value={this.state.currentDataStream} onChange={this.handleDataStreamSelection}>
                                        {datastream_options}
                                        </select>
                                    </label>
                                </div>
                                <div className="row range-section">
                                    <div className="form-group col-5">
                                        <h5>
                                            Event names
                                        </h5>
                                        {this.renderEventNameList()}
                                        
                                    </div>
                                    <div className="form-group col">
                                        <h5>
                                            Range
                                        </h5>
                                        {this.renderEventRangeList()}
                                        
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={(e) => this.handleAddRange(e)}>+Add</button>
                            <button type="button" className="btn btn-primary" onClick={(e) => this.handleSubmitDataTransformation(e)}>Submit</button>
                        </form>
                    </div>
                    <div className="col event-transformation-section shadow-lg">
                        <h3>Event Operations</h3>
                        <div id="event-operation-list">
                            {/* Event Operation list goes here.. */}
                            {this.renderEventOperationList()}
                        </div>
                        <form className="border-top spacer">
                            <h5>Add new event operation:</h5>
                            <label htmlFor="ce-title">Event Operation Title</label>
                            <input type="text" id="ce-title" className="form-control col-5" placeholder="Enter event operation title..." 
                            onChange = {(e) => this.handleEventOperationResultNameChange(e)}></input>
                            <label className="event-section" htmlFor="ce-options">Events and Operation</label>
                            <div id="ce-options" className="col-10 row">
                                <select className="form-control col" id="ce-first-option" onChange={this.handleEventDropdownChange1} value={this.state.eve}>
                                    {event_options}
                                </select>
                                <select className="form-control col input-section" id="logical-option" onChange={this.handleEventOperatorDropdownChange}>
                                    <option value="AND" key="AND">AND</option>
                                    <option value="OR" key="OR">OR</option>
                                </select>
                                <select className="form-control col input-section" id="ce-second-option" onChange={this.handleEventDropdownChange2}>
                                    {event_options}
                                </select>
                            </div>
                            <button type="button" className="btn btn-primary" id="addComplexEvent" onClick={(e)=>this.addComplexEvent(e)}>+Add</button>
                        </form>
                    </div>
                </div>
                
                <div className="row">
                    
                    <div className="col pattern-selection-section shadow-lg">
                        <h3>Select Event from pattern</h3>
                        <div id="selected-event-list">
                            {/* Event Selection list goes here.. */}
                            {this.renderEventSelectionList()}
                        </div>
                        <form className="border-top spacer">
                            <h5>Select new eventstream from a pattern:</h5>
                            <label htmlFor="ce-title">Event Stream Title</label>
                            <input type="text" id="pattern-event-title" className="form-control col-5" placeholder="Enter event stream title..." 
                            onChange = {(e) => this.handlePatternSelectionNameChange(e)}></input>
                            <label className="pattern-event-section" htmlFor="pattern-options">Patterns and Events</label>
                            <div id="pattern-options" className="col-10 row">
                                <select className="form-control col" id="pattern-option" onChange={this.handlePatternDropdownChange} value={this.state.eve}>
                                    {pattern_options}
                                </select>
                                <select className="form-control col input-section" id="pattern-event-option" onChange={this.handlePatternEventSelectionDropdownChange}>
                                    {this.renderPatternEventOptions()}
                                </select>
                                
                            </div>
                            <button type="button" className="btn btn-primary" id="addPatternEvent" onClick={(e)=>this.addPatternEventSelection(e)}>Add</button>
                        </form>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default EventOperations;